import * as _ from 'lodash';
import {
  ViewContainerRef,
  ComponentRef,
  Type,
  Injectable,
  ComponentFactory,
  ComponentFactoryResolver,
  ResolvedReflectiveProvider,
  ReflectiveInjector,
  Injector,
  Provider
} from '@angular/core';
import { Observable ,  Subject ,  Subscription } from 'rxjs';
import { ModalStorageService } from './modal-storage.service';
import { ModalAnchorDirective } from '../../directives/index';
import { ModalSettings } from '../../../core/models/index';
import { ModalBehavior, ModalRef } from '../../models/index';
import { Assert } from '../../../framework/index';
import { PopupRef } from '@progress/kendo-angular-popup';
import { PopupService } from './popup.service';
import { PopupSettings } from '../../models/popup-settings';
import { TitleWindowComponent } from '../../components/title-window/slx-title-window.component';

@Injectable()
export class ModalService {

  public get globalAnchor(): ModalAnchorDirective {
    Assert.isNotNull(this.modalAnchorDirective, 'globalAnchor');
    return this.modalAnchorDirective;
  }

  private componentFactoryResolver: ComponentFactoryResolver;
  private injector: Injector;
  private modalStorageService: ModalStorageService;
  private currentType: string;

  private onClose$: Observable<any>;
  private onOpen$: Observable<any>;
  private onOpenSource: Subject<any>;
  private onCloseSource: Subject<{ uniqId: string }>;

  private modalsHash: StringMap<PopupRef>;
  private subscriptionsHash: StringMap<Subscription>;
  private settingsHash: StringMap<ModalSettings>;
  private globalUId: string;
  private modalAnchorDirective: ModalAnchorDirective;

  constructor(componentFactoryResolver: ComponentFactoryResolver, injector: Injector,
    modalStorageService: ModalStorageService, private popupService: PopupService) {
    Assert.isNotNull(componentFactoryResolver, 'componentFactoryResolver');
    Assert.isNotNull(injector, 'injector');
    Assert.isNotNull(modalStorageService, 'modalStorageService');
    this.componentFactoryResolver = componentFactoryResolver;
    this.injector = injector;
    this.modalStorageService = modalStorageService;
    this.modalsHash = {};
    this.subscriptionsHash = {};
    this.settingsHash = {};
    this.globalUId = 'global';

    this.createObservables();
  }

  public setGlobalAnchor(modalAnchorDirective: ModalAnchorDirective): void {
    //if (this.modalAnchorDirective) {
    // throw new Error('Global anchor already exist');
    //return;
    //}
    this.modalAnchorDirective = modalAnchorDirective;
  }

  public open<TModal>(type: Type<TModal>, element: ViewContainerRef, title?: string, providers?: Provider[], uniqueId?: string): ModalRef<TModal> {
    Assert.isNotNull(type, 'type');
    Assert.isNotNull(element, 'element');

    let settings: ModalSettings = this.createDefaultModalSettings();
    if (title) {
      settings.title = title;
    }
    return this.openInternal(type, element, settings, providers, uniqueId);
  }
  public openWithSaved<TModal>(type: Type<TModal>, element: ViewContainerRef, title?: string, providers?: Provider[], behavior?: string[], uniqueId?: string): ModalRef<TModal> {
    Assert.isNotNull(type, 'type');
    Assert.isNotNull(element, 'element');

    let settings: ModalSettings = this.getModalSettings(type.name);
    if (behavior) {
      settings.actions = behavior;
    }
    if (title) {
      settings.title = title;
    }
    return this.openInternal(type, element, settings, providers, uniqueId);
  }
  public openEx<TModal>(type: Type<TModal>, element: ViewContainerRef, settings: ModalSettings, providers?: Provider[], uniqueId?: string): ModalRef<TModal> {
    Assert.isNotNull(type, 'type');
    Assert.isNotNull(element, 'element');
    return this.openInternal(type, element, settings, providers, uniqueId);
  }

  public closeAllWindows(): void {
    _.forEach(this.modalsHash, (popup: PopupRef) => {
      popup.close();
    });
  }

  public setTitle(title: string, uniqueId: string): void {
    const selector=`slx-title-window .window-container[modalid='${uniqueId}'] .title-label`;
    const header = document.querySelector(selector);
    header.innerHTML = title;
  }
  public closeWindow(uniqueId: string): void {
    Assert.isOwnProperty(this.modalsHash, uniqueId, 'Modal Window not exist');
    let popup: PopupRef = this.modalsHash[uniqueId];
    popup.close();
  }

  public createDefaultModalSettings(): ModalSettings {
    let settings: ModalSettings = {
      name: '',
      actions: [ModalBehavior.maximize, ModalBehavior.close],
      autoFocus: true,
      draggable: false,
      iframe: false,
      height: '100%',
      width: '100%',
      maxHeight: null,
      maxWidth: null,
      minHeight: null,
      minWidth: null,
      top: 0,
      left: 0,
      modal: true,
      pinned: false,
      resizable: false,
      scrollable: false,
      title: '',
      visible: false,
      maximized: true,
      displayBlock: false,
      useWindowWrapper: true,
      showCloseButton: true,
      className: '',
      topmost: false,
      zIndexForTopMost: 0,
      headerClassName: '',
      ifHeaderIcon : false,
      headerIconTooltip:''
    };
    return settings;
  }

  public setModalSettings(type: string, settings: ModalSettings): void {
    this.modalStorageService.setModalSettings(type, settings);
  }

  public getModalSettings(type: string): ModalSettings {
    let settings: ModalSettings;
    if (this.modalStorageService.isExist(type)) {
      settings = this.modalStorageService.getModalSettings(type);
    } else {
      settings = this.createDefaultModalSettings();
      this.modalStorageService.setModalSettings(type, settings);
    }
    return settings;
  }

  public subscribeOnOpen(callback: (e: any) => void): void {
    Assert.isNotNull(callback, 'callback');
    this.onOpen$.subscribe(callback);
  }

  public subscribeOnClose(callback: (e: any) => void): void {
    Assert.isNotNull(callback, 'callback');
    this.onClose$.subscribe(callback);
  }

  public getOpenedDialogsCount(): number {
    return _.keys(this.modalsHash).length;
  }

  private openInternal<TModal>(type: Type<TModal>, element: ViewContainerRef, settings: ModalSettings, providers?: Provider[], uniqueId?: string): ModalRef<TModal> {

    Assert.isNotNull(type, 'type');
    Assert.isNotNull(element, 'element');
    Assert.isNotNull(settings, 'settings');

    this.currentType = type.name;

    let popupSettings: PopupSettings = {};
    popupSettings.content = type;
    popupSettings.anchor = element.element;
    popupSettings.animate = true;
    popupSettings.popupClass = 'full-size-popup';

    if (providers) {
      popupSettings.providers = providers;
    }

    if (element) {
      popupSettings.appendTo = element;
    }

    popupSettings.anchorAlign = { horizontal: 'center', vertical: 'center' };
    popupSettings.popupAlign = { horizontal: 'center', vertical: 'center' };

    if (!uniqueId) {
      uniqueId = this.guid();
    }

    let reference: PopupRef;

    if (settings.useWindowWrapper) {
      let wrappedResult: { wrapper: ComponentRef<any>, ref: PopupRef } = this.popupService.openWithWindowWrapper(TitleWindowComponent, popupSettings);
      reference = wrappedResult.ref;
      let wrapper: any = wrappedResult.wrapper.instance;
      wrapper.title = settings.title;
      wrapper.modalId = uniqueId;
      wrapper.showCloseButton = settings.showCloseButton;
      wrapper.className = settings.className;
      wrapper.headerClassName = settings.headerClassName;
      wrapper.ifHeaderIcon  = settings.ifHeaderIcon;
      wrapper.headerIconTooltip = settings.headerIconTooltip;
      if (settings.showCloseButton) {
        let popupCloseByButtonSubscription: Subscription = wrapper.onCloseByButton.subscribe((modalId: string) => {
          this.closeWindow(modalId);
        });
      }
    } else {
      reference = this.popupService.open(popupSettings);
    }

    if (settings.maximized) settings.width = settings.height = '100%';

    const popupElement = reference.popup.instance.container.nativeElement;
    const popupChildAnimationElement2 = popupElement?.querySelector('.headless-modal-content');
    
    if (settings.width) {
      if (typeof settings.width === 'string') {
        this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'width', settings.width);
      } else {
        this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'width', settings.width + 'px');
      }
    }

    if (settings.height) {
      if (typeof settings.height === 'string') {
        this.globalAnchor.setElementStyle(popupElement, 'height', settings.height);
        popupChildAnimationElement2 && this.globalAnchor.setElementStyle(popupChildAnimationElement2, 'height', settings.height);
      } else {
        this.globalAnchor.setElementStyle(popupElement, 'height', settings.height + 'px');
        popupChildAnimationElement2 && this.globalAnchor.setElementStyle(popupChildAnimationElement2, 'height', settings.height + 'px');
      }
    }

    if (settings.minWidth) {
      if (typeof settings.minWidth === 'string') {
        this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'min-width', settings.minWidth);
      } else {
        this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'min-width', settings.minWidth + 'px');
      }
    }

    if (settings.minHeight) {
      if (typeof settings.minHeight === 'string') {
        this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'min-height', settings.minHeight);
      } else {
        this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'min-height', settings.minHeight + 'px');
      }
    }

    if(settings.topmost){
      this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'z-index', settings.zIndexForTopMost);
    }

    if (settings.maxHeight !== undefined) {
      this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'max-height', settings.maxHeight);
    }

    Assert.isNotOwnProperty(this.modalsHash, uniqueId, 'Modal Window already exist');
    this.modalsHash[uniqueId] = reference;
    let popupCloseSubscription: Subscription = reference.popupClose.subscribe(() => {
      this.onClose(reference);
    });

    this.subscriptionsHash[uniqueId] = popupCloseSubscription;
    this.settingsHash[uniqueId] = settings;

    return { uniqueId: uniqueId, reference: reference.content.instance };

  }

  private createWindowOptions<TModal>(settings: ModalSettings, type: string): kendo.ui.WindowOptions {
    Assert.isNotNull(settings, 'settings');
    Assert.isNotNull(type, 'type');

    let options: kendo.ui.WindowOptions = {
      actions: settings.actions,
      autoFocus: settings.autoFocus,
      draggable: settings.draggable,
      height: settings.height,
      width: settings.width,
      modal: settings.modal,
      pinned: settings.pinned,
      resizable: settings.resizable,
      scrollable: true,
      title: settings.title,
      visible: false,
      minWidth: settings.minWidth,
      minHeight: settings.minHeight
    };
    return options;
  }

  private createObservables(): void {
    this.onOpenSource = new Subject<any>();
    this.onCloseSource = new Subject<{ uniqId: string }>();
    this.onOpen$ = this.onOpenSource.asObservable();
    this.onClose$ = this.onCloseSource.asObservable();
  }

  private onOpen(popupRef: PopupRef): void {

    Assert.isNotNull(popupRef, 'ModalService onOpen popupRef');

    let uniqId = _.findKey(this.modalsHash, (ref: PopupRef) => {
      return ref === popupRef;
    });


    if (this.onOpenSource) {
      this.onOpenSource.next({ uniqId: uniqId });
    }
  }

  private onClose(popupRef: PopupRef): void {

    Assert.isNotNull(popupRef, 'ModalService onClose popupRef');

    let uniqId = _.findKey(this.modalsHash, (ref: PopupRef) => {
      return ref === popupRef;
    });

    if (uniqId) {
      let subscription: Subscription = this.subscriptionsHash[uniqId];
      if (subscription) subscription.unsubscribe();
      delete this.subscriptionsHash[uniqId];
      delete this.modalsHash[uniqId];
      this.saveSettings(this.settingsHash[uniqId]);
      delete this.settingsHash[uniqId];
    }

    if (this.onCloseSource) {
      this.onCloseSource.next({ uniqId: uniqId });
    }

  }

  private clearSuscriptions(): void {
    if (this.onCloseSource) {
      this.onCloseSource.unsubscribe();
      this.onCloseSource = null;
    }
    if (this.onOpenSource) {
      this.onOpenSource.unsubscribe();
      this.onOpenSource = null;
    }
  }


  private saveSettings(popupSettings: ModalSettings): void {
    Assert.isNotNull(popupSettings, 'ModalService popupSettings');
    let settings: ModalSettings = this.getModalSettings(this.currentType);
    settings.maximized = popupSettings.maximized;
    if (!settings.maximized) {
      settings.height = popupSettings.height;
      settings.width = popupSettings.width;
    }
    this.setModalSettings(this.currentType, settings);
  }

  private guid(): string {
    return Math.random().toString(36).substr(2, 9);
  }

}
