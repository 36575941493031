import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { HttpParams } from '@angular/common/http';



import { Credentials } from '../../models/index';
import { User, Session, AuthResponse, AuthStatusCode } from '../../../state-model/models/index';
import { AuthenticationService } from '../../services/index';
import { LoggerService } from '../../../core/services/index';
import { Assert, StringUtils } from '../../../framework/index';
import { ResponseBody, Meta, StatusCodes } from '../../../core/models/index';
import { authenticationConfig } from '../../authentication.config';
import { VersionSubscribeService } from '../../../common/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { UserMenuPinService } from '../../../core/services/user-menu-pin/user-menu-pin.service';
import { NotificationsApiService } from '../../../../app/portal/services/notifications/notifications-api.service';

@Component({
  moduleId: module.id,
  selector: 'slx-login-form',
  templateUrl: 'login-form.component.html',
  styleUrls: ['login-form.component.scss']
})
export class LoginFormComponent {
  @Output()
  public onAuthenticated: EventEmitter<User>;
  @Output()
  public logout: EventEmitter<string>;
  @Output()
  public authenticationFailed: EventEmitter<any>;

  public credentials: Credentials;

  public responseErrorMessage: string;

  @Input()
  public set username(value: string) {
    this.credentials.username = value;
  }

  @Input()
  public isLockedByUser: boolean = false;

  @Input()
  public showLogoutButton: boolean = false;

  @Input()
  public handleLoginRedirection: boolean = true;

  public state: {
    isLoading: boolean;
    isInvalidCredentials: boolean;
    isInvalidLogin: boolean;
    isConflictError: boolean;
    isUnknownError: boolean;
    showPassword: boolean;
    isInValidIPAddress: boolean;
  };

  constructor(private readonly authenticationService: AuthenticationService, private readonly loggerService: LoggerService, private readonly router: Router, private readonly route: ActivatedRoute, private readonly versionSubscriber: VersionSubscribeService, private readonly userActivityService: UserActivityService,private userMenuPinService: UserMenuPinService, private readonly messageCenterNotification: NotificationsApiService) {
    Assert.isNotNull(route, 'route');
    Assert.isNotNull(router, 'router');
    Assert.isNotNull(loggerService, 'loggerService');
    Assert.isNotNull(authenticationService, 'authenticationService');

    this.onAuthenticated = new EventEmitter<User>();
    this.authenticationFailed = new EventEmitter<any>();
    this.logout = new EventEmitter<string>();
    this.credentials = new Credentials();
    this.state = {
      isLoading: false,
      isInvalidCredentials: false,
      isInvalidLogin: false,
      isConflictError: false,
      isUnknownError: false,
      showPassword: false,
      isInValidIPAddress: false
    };
  }

  public onLogin(): void {
    this.state.isLoading = true;
    this.state.isInvalidCredentials = false;
    this.state.isInvalidLogin = false;
    this.state.isConflictError = false;
    this.state.isUnknownError = false;
    this.state.showPassword = false;

    let usernameAndAlias: string[] = this.credentials.username ? this.credentials.username.split(authenticationConfig.login.aliasSeparator) : [''];
    let username: string = usernameAndAlias[0];
    let alias: string = usernameAndAlias.length === 2 ? usernameAndAlias[1] : '';
   
    this.authenticationService.authenticate(username, this.credentials.password, alias, !this.isLockedByUser)
      .then((authResponse: AuthResponse) => {
        this.onAuthenticationSucceeded(authResponse);
      })
      .catch((error: ResponseBody<any, Meta>) => this.onAuthenticationFailed(error));
  }

  public onLogout(): void {
    this.logout.emit(this.credentials ? this.credentials.username : undefined);
  }

  public hideInvalidCredentialsErrorMessage(): void {
    this.state.isInvalidCredentials = false;
  }

  public hideInvalidLoginErrorMessage(): void {
    this.state.isInvalidLogin = false;
  }

  public hideConflictErrorMessage(): void {
    this.state.isConflictError = false;
  }

  public hideUnknownErrorMessage(): void {
    this.state.isUnknownError = false;
  }

  public togglePasswordVisibility(): void {
    this.state.showPassword = !this.state.showPassword;
  }

  public loginChanged(): void {
    if(!!this.credentials.password) {
        this.credentials.password = '';
    }
  }

  private onAuthenticationSucceeded(authResponse: AuthResponse): void {
    
    Assert.isNotNull(authResponse, 'authResponse');
    this.messageCenterNotification.buildConnection();
    this.messageCenterNotification.startConnection();
    if (authResponse.statusCode === AuthStatusCode.valid) {
      Assert.isNotNull(authResponse.session, 'session');
      this.onAuthenticated.emit(authResponse.session.user);
      if (this.handleLoginRedirection) {
        this.redirectLoggedInUser();
      }
    } else if (authResponse.statusCode === AuthStatusCode.resetRequested || authResponse.statusCode === AuthStatusCode.passwordExpired) {

      let usernameAndAlias: string[] = this.credentials.username ? this.credentials.username.split(authenticationConfig.login.aliasSeparator) : [''];
      let username: string = usernameAndAlias[0];
      let alias: string = usernameAndAlias.length === 2 ? usernameAndAlias[1] : '';
      this.authenticationService.navigateToResetPassword(username, alias);
    }
    else if (authResponse.statusCode === AuthStatusCode.invalidUserOrPassword)
    {
      this.state.isLoading = false;
      this.state.isInvalidCredentials = true;
      
    }
    //
    else if(authResponse.statusCode === AuthStatusCode.invalidIPAddress)
    {
      this.state.isLoading = false;
      this.state.isInvalidCredentials = true;
      this.redirectInavlidIPAddress();
    }
  }
  public redirectInavlidIPAddress(): void {
   
     // let username:null;let alias:null;
      this.authenticationService.navigateToInvalidIPAddress();

  }
  private redirectLoggedInUser(): void {
    this.userActivityService.start();
    this.userMenuPinService.getUserMenuPinStatus();
    if (this.authenticationService.isAliasChanged) {
      this.router.navigate(['apps']);
      return;
    }

    let returnUrl: string = this.route.snapshot.queryParams[authenticationConfig.login.returnUrlQueryParameter];
    let returnQs: string = this.route.snapshot.queryParams[authenticationConfig.login.returnUrlQueryStringParameter];

    let queryParams: Params = {};

    if (!StringUtils.isNullOrEmpty(returnQs)) {
      let params: HttpParams = new HttpParams({
        fromString: returnQs
      });

      params.keys().forEach((value: string) => {
        queryParams[value] = params.get(value);
      });
    }

    let url: string = StringUtils.isNullOrEmpty(returnUrl) ? authenticationConfig.login.defaultReturnUrl : returnUrl;
    let navigationExtras: NavigationExtras = {
      queryParams: queryParams
    };

    this.versionSubscriber.get();
    this.router.navigate([url], navigationExtras);
  }

  private onAuthenticationFailed(error: ResponseBody<any, Meta>): void {
    Assert.isNotNull(error, 'error');

    this.state.isLoading = false;
    
    switch (error.status) {      
      case StatusCodes.unauthorized:
        this.state.isInvalidCredentials = true;
        break;
      case StatusCodes.notFound:
        this.state.isInvalidLogin = true;
        break;
      case StatusCodes.conflict:
        this.responseErrorMessage = error.meta.error;
        this.state.isConflictError = true;
        break;
        case StatusCodes.inValidIPAddress:
        this.responseErrorMessage = error.meta.error;
        this.state.isInValidIPAddress = true;
        break;
      default:
        this.state.isUnknownError = true;
        this.loggerService.error('Unexpected error during authentication', error);
        break;
    }
    this.authenticationFailed.emit();
  }
}
