<div provide-parent-form>

  <section class="shift-position">
    <slx-input-decorator class="position-field">
      <slx-dropdown-input slx-input required="true" titleField="name" placeholder="Position" name="position{{index}}" [options]="container.positions"
        [ngModel]="container.position" (ngModelChange)="positionChanged($event)" [disabledOption]="container.disabledPosition">
      </slx-dropdown-input>
      <span errorMessage for="required">Position is required</span>
    </slx-input-decorator>
    <slx-input-decorator class="department-field">
      <input slx-input type="text" readonly="true" placeholder="Department" name="department{{index}}" [ngModel]="container.department?.name"
      />
    </slx-input-decorator>
  </section>

  <section class="shift-main">

    <slx-input-decorator>
      <slx-dropdown-input slx-input required="true" autoSelectFirst="true" placeholder="Shift" name="shift{{index}}" [options]="container.shifts"
        [ngModel]="container.selectedShiftDefinition" (ngModelChange)="shiftChange($event)"></slx-dropdown-input>
      <span errorMessage for="required">Shift is required</span>
      <span errorMessage for="overlap"></span>
    </slx-input-decorator>

    <slx-input-decorator *ngIf="!container.hasScheduleAbsence">
      <slx-dropdown-input slx-input autoSelectFirst="true" placeholder="Unit" name="unit{{index}}" required="true" [options]="container.units"
        [ngModel]="container.unit" (ngModelChange)="unitChange($event)"></slx-dropdown-input>
      <span errorMessage for="required">Unit is required</span>
    </slx-input-decorator>

    <slx-input-decorator *ngIf="!container.hasScheduleAbsence && !!container.constraint">
      <slx-dropdown-input slx-input autoSelectFirst="true" placeholder="Constraint" name="constraint{{index}}" required="true"
        [options]="container.constraints" [ngModel]="container.constraint" (ngModelChange)="constraintChange($event)"></slx-dropdown-input>
    </slx-input-decorator>

    <slx-input-decorator *ngIf="container.hasScheduleAbsence">
      <slx-dropdown-input slx-input autoSelectFirst="true" placeholder="Absence" name="absence{{index}}" required="true" autoSelectFirst="true"
        valueField="code" titleField="description" [options]="container.absences" [ngModel]="container.absence" (ngModelChange)="absenceChange($event)"></slx-dropdown-input>
      <span errorMessage for="required">Absence is required</span>
    </slx-input-decorator>

    <div class="spacer" *ngIf="container.hasScheduleAbsence || !container.constraint"></div>

    <div class="time-group">
      <slx-input-decorator #startTimeContainer>
        <input slx-time-picker slx-input name="startDate{{index}}" required="true" placeholder="Start Time" [readonly]="container.startDateLocked"
          [(ngModel)]="container.shiftStartDate" (onDateChanged)="onShiftStartDateChanged ()" format="hh:mm tt" dateInput="true"
          [slxMaxDate]="container.shiftEndDate" [slxMinDate]="container.minDate" slxDatePager [pagerMinDate]="container.minDate"
          [pagerMaxDate]="container.maxDate" [targetContainer]="startTimeContainer" btnLeftClass="prev-day-btn" btnRightClass="next-day-btn"
          styleContainerClass="slx-date-pager-date">
        <span errorMessage for="required">Start time is required</span>
        <span errorMessage for="minDate">Previous day shift end ({{container.minDate | amDateFormat: appConfig.dateTimeFormatYearless}})</span>
        <span errorMessage for="maxDate">Start time should be less than end time</span>
        <span slx-input-hint>{{container.shiftStartDate | amDateFormat: appConfig.monthDayDateFormat}}</span>
      </slx-input-decorator>

      <slx-input-decorator #endTimeContainer>
        <input slx-time-picker slx-input name="endDate{{index}}" required="true" placeholder="End Time" [readonly]="container.endDateLocked"
          [(ngModel)]="container.shiftEndDate" (onDateChanged)="onShiftEndDateChanged ()" format="hh:mm tt" dateInput="true"
          [slxMaxDate]="container.maxDate" [slxMinDate]="container.shiftStartDate" slxDatePager [pagerMinDate]="container.minDate"
          [pagerMaxDate]="container.maxDate" [targetContainer]="endTimeContainer" btnLeftClass="prev-day-btn" btnRightClass="next-day-btn"
          styleContainerClass="slx-date-pager-date">
        <span errorMessage for="required">End time is required</span>
        <span errorMessage for="minDate">End time should be greater than start time</span>
        <span errorMessage for="maxDate">Next day shift start ({{container.maxDate | amDateFormat: appConfig.dateTimeFormatYearless}})</span>
        <span slx-input-hint>{{container.shiftEndDate | amDateFormat: appConfig.monthDayDateFormat}}</span>
      </slx-input-decorator>

      <slx-input-decorator class="hours-field">
        <slx-kendo-number slx-input [(ngModel)]="container.shiftHours" (ngModelChange)="shiftPaidTimeChanged($event)" [min]="0" [slxMin]="0"
          [slxMinStrict]="true" [decimals]="2" [required]="true" [readonly]="!container.selectedShiftDefinition || !!container.startDateLocked || !!container.endDateLocked"
          [placeholder]="container.hasScheduleAbsence ? 'Hours' : 'Paid Hours'" name="paidHours{{index}}"></slx-kendo-number>
        <span errorMessage for="min">Hours should be greater than zero</span>
        <span errorMessage for="required">Hours are required</span>
      </slx-input-decorator>

      <slx-input-decorator class="hours-field" *ngIf="!container.hasScheduleAbsence">
        <slx-kendo-number slx-input [(ngModel)]="container.unpaidHours" (ngModelChange)="shiftUnpaidTimeChanged($event)" [min]="0"
          [slxMin]="0" [decimals]="2" [required]="true" [readonly]="!container.selectedShiftDefinition || !!container.startDateLocked || !!container.endDateLocked"
          placeholder="Unpaid Hours" name="unpaidHours{{index}}"></slx-kendo-number>
        <span errorMessage for="min">Hours should be greater than zero</span>
        <span errorMessage for="required">Hours are required</span>
      </slx-input-decorator>
    </div>
    <span class="error-label" *ngIf="container.hasOverlap">Please, remove overlap</span>
    <span class="error-label" *ngIf="container.hasDuplicate">Same shift can't be used twice</span>

  </section>

</div>
