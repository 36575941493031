import { ShiftDefinition } from './../../../organization/models/lookup/shift-definition';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import {
  Details, DetailRow,
  DetailField, DetailGroup,
  DetailColumn, DetailGrouping,
  ShiftEligibleEmployee,
  DetailsPartner,
  IDetailsPartner,
  DetailsPartnerAttributes,
  AttributesModel,
  DetailsShiftRequest, 
  IDetailsShiftRequest,
  AttributeValue
} from '../../models/index';
import { CancelType } from '../../models/detail-screen/cancel-type';

@Injectable()
export class DetailScreenMapService {
  public mapToDetails(data: any, meta: any, shifts: ShiftDefinition[]): Details {
    let details: Details = new Details();
    details.censusCount = data.census;
    details.dateOn = data.dateOn;
    details.canReplaceEmployee = data.canReplaceEmployee;
    details.canAgencyStaffingRequest = data.canAgencyStaffingRequest;
    details.canPartnerModule = data.canPartnerModule;
    details.canAgencyShiftCancelRequest = data.canAgencyShiftCancelRequest;
    details.canEditScheduledApprovePayPeriod = data.canEditScheduledApprovePayPeriod;
    details.canEditOpenShiftCountAction = data.canEditOpenShiftCount;
    details.totalHours = data.totalHours;
    if (meta.fields && meta.fields.length) {
      details.columns = _.map(meta.fields, (field: any) => this.mapToColumn(field));
    } else {
      details.columns = [];
    }
    if (data.groups && data.groups.length) {
      details.groups = _.map(data.groups, (group: any) => this.mapToGroup(group, shifts, details.censusCount));
    } else {
      details.groups = [];
    }
    return details;
  }

  public mapToGroup(data: any, shifts: ShiftDefinition[], census: number): DetailGroup {
    let group: DetailGroup = new DetailGroup();
    group.census = census;
    group.postedShiftRepliesCount = data.data.postedShiftRepliesCount;
    group.smsRepliesCount = data.data.smsRepliesCount;
    group.parLevel = data.data.parLevel;
    group.idealPpd = data.data.idealSchedulePPD;
    group.scheduledPpd = data.data.scheduledPPD;
    group.groupedIdealScheduleHours = data.data.groupedIdealScheduleHours;
    group.groupedScheduledHours = data.data.groupedScheduledHours;
    group.groupedScheduledCount = data.data.groupedScheduledCount;
    group.grouping = _.map(data.groupingInfo, (info: any) => this.mapToGrouping(info));
    let dg: DetailGrouping = _.find(group.grouping, (gr: DetailGrouping) => gr.name === 'ShiftGroup');
    group.name = dg ? dg.value : 'Empty';
    group.rows = _.map(data.data.items, (item: any) => this.mapToRow(item));
    return group;
  }

  public mapToGrouping(data: any): DetailGrouping {
    let grouping: DetailGrouping = new DetailGrouping();
    grouping.name = data.name;
    grouping.value = data.value;
    return grouping;
  }

  public mapToRow(data: any): DetailRow {
    let row: DetailRow = new DetailRow();
    row.fields = _.map(data.fields, (field: any) => this.mapToField(field));
    return row;
  }

  public mapToField(data: any): DetailField {
    let field: DetailField = new DetailField();
    field.name = data.fieldName;
    field.value = data.value;
    return field;
  }

  public mapToColumn(data: any): DetailColumn {
    let column: DetailColumn = new DetailColumn();
    column.name = _.startCase(data.fieldName).split(' ').join('');
    column.access = data.access;
    column.fieldLimitInfo = data.fieldLimitInfo;
    column.displayName = data.displayName;
    column.type = data.fieldType;
    return column;
  }

  public mapShiftEligibleEmployeeToDetailRow(data: ShiftEligibleEmployee): DetailRow {
    let row: DetailRow = new DetailRow();
    row.fields = [];
    row.fields.push(this.mapToField({ fieldName: 'EmpId', value: data.employee.id }));
    row.fields.push(this.mapToField({ fieldName: 'EmpName', value: data.employee.name }));
    row.fields.push(this.mapToField({ fieldName: 'DepartmentId', value: data.department.id }));
    row.fields.push(this.mapToField({ fieldName: 'DepartmentName', value: data.department.id }));
    row.fields.push(this.mapToField({ fieldName: 'JobCode', value: data.position.id }));
    row.fields.push(this.mapToField({ fieldName: 'JobDescription', value: data.position.name }));
    // row.fields.push(this.mapToField({ fieldName: 'ShiftId', value: data.employee }));
    // row.fields.push(this.mapToField({ fieldName: 'ShiftName', value: data.employee.id }));
    // row.fields.push(this.mapToField({ fieldName: 'UnitId', value: data.employee.id }));
    // row.fields.push(this.mapToField({ fieldName: 'UnitName', value: data.employee.id }));
    // row.fields.push(this.mapToField({ fieldName: 'Hours', value: data.employee.id }));
    return row;
  }

  public mapToPartnerDetails(data: DetailsPartner[]): IDetailsPartner[] {
    let partners: DetailsPartner[] = [];
    _.each(data, (dto: IDetailsPartner) => {
      let position: DetailsPartner = this.mapToPartnerAgancyDetails(dto);
      partners.push(position);
    });
    return partners;
  }

  public mapToPartnerAgancyDetails(data: any): DetailsPartner {
    let partner: DetailsPartner = new DetailsPartner();
    partner.id = data.id || null;
    partner.partner_id = data.partner_id || null;
    partner.sub_type_id = data.sub_type_id || null;
    partner.sub_type_name = data.sub_type_name || null;
    partner.agency_id = data.agency_id || null;
    partner.status = data.status || null;
    if(data.partner_attributes.length > 0){
      let jsonData = JSON.parse(data.partner_attributes);
      let attributeList: DetailsPartnerAttributes[] = [];
      jsonData.forEach( attributedto => {
        let item:DetailsPartnerAttributes = new DetailsPartnerAttributes();
        item.name = attributedto.name;
        item.display_name = attributedto.display_name;
        item.type = attributedto.type;
        item.attr_default_values = attributedto.attr_default_values;
        let jsonValues = JSON.parse(attributedto.attr_values);
        item.attr_values = _.map(jsonValues, (field: any) => this.mapToAttrValues(field));
        attributeList.push(item);
      });
      partner.partner_attributes = attributeList;
    }else{
      partner.partner_attributes = [];
    }
    if(data.partner_actions.length > 0){
      let CancelTypes: CancelType[] = [];
      data.partner_actions.forEach(type=>{
        let cancelType:CancelType=new CancelType();
        cancelType.name=type;
        CancelTypes.push(cancelType);
      });
      partner.cancelType = CancelTypes;
    }
    partner.agency_grace_time = data.partner_properties.cancel_warning_duration
   
    return partner;
  }

  public mapToAttrValues(data: any): AttributeValue {
    let field: AttributeValue = new AttributeValue();
    field.id = data.id;
    field.name = data.name;
    field.value = data.value=="true";
    return field;
  }

  public mapToShiftRequestDetails(data: DetailsShiftRequest[]): IDetailsShiftRequest[] {
    let partners: DetailsShiftRequest[] = [];
    _.each(data, (dto: IDetailsShiftRequest) => {
      let details: DetailsShiftRequest = this.mapToPartnerShiftDetails(dto);
      partners.push(details);
    });
    return partners;
  }

  public mapToPartnerShiftDetails(data: any): DetailsShiftRequest {
    let shiftDetails: DetailsShiftRequest = new DetailsShiftRequest();
    shiftDetails.requestId = data.requestId || null;
    shiftDetails.trackingId = data.trackingId || null;
    shiftDetails.shiftrequestBy = data.shiftrequestBy || null;
    shiftDetails.shiftStatus = data.shiftStatus!=null ? data.shiftStatus : null;
    shiftDetails.agencyId = data.staffingAgency.agencyId || null;
    shiftDetails.partnerId = data.staffingAgency.partnerId || null;
    shiftDetails.agencyOrgLevelId = data.staffingAgency.agencyOrgLevelId || null;
    let attributeList: AttributesModel[] = [];
    if(data.staffingAgency.attributes.length > 0){
      let jsonData = data.staffingAgency.attributes;
      jsonData.forEach(attributedto => {
        let item:AttributesModel = new AttributesModel();
        item.id = attributedto.id;
        item.name = attributedto.name;
        item.partnerAttrId = attributedto.partnerAttrId;
        item.value = attributedto.value;
        item.attrValues = attributedto.attrValues;
        attributeList.push(item);
      });
    }
    shiftDetails.attributes = attributeList;
    return shiftDetails;
  }


}
