export * from './audit-trail/audit-trail.component';
export * from './audit-trail/audit-trail-grid/audit-trail-grid.component';

export * from './audit-trail-limited/audit-trail-limited/audit-trail-limited.component';
export * from './audit-trail-limited/audit-trail-limited-grid/audit-trail-limited-grid.component';
export * from './audit-trail-limited/audit-trail-limited-dialog/audit-trail-limited-dialog.component';


import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { AuditTrailGridComponent } from './audit-trail/audit-trail-grid/audit-trail-grid.component';

import { AuditTrailLimitedComponent } from './audit-trail-limited/audit-trail-limited/audit-trail-limited.component';
import { AuditTraiLimitedGridComponent } from './audit-trail-limited/audit-trail-limited-grid/audit-trail-limited-grid.component';
import { AuditTrailLimitedDialogComponent } from './audit-trail-limited/audit-trail-limited-dialog/audit-trail-limited-dialog.component';
import { AuditTrailConfigComponent } from './audit-trail-config/audit-trail-config.component';
import { AuditTrailConfigGridComponent } from './audit-trail-config/audit-trail-config-grid/audit-trail-config-grid.component';

export const entryAuditTrailComponents: any[] = [
  AuditTrailLimitedDialogComponent
];

export const components: any[] = [
  AuditTrailComponent,
  AuditTrailGridComponent,
  AuditTrailConfigComponent,
  AuditTrailConfigGridComponent,
  AuditTrailLimitedComponent,
  AuditTraiLimitedGridComponent,
  AuditTrailLimitedDialogComponent
];
