
import {combineLatest} from 'rxjs/operators';
import { Component, OnInit,Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, UrlSegment } from '@angular/router';
import * as _ from 'lodash';

import { select } from '@angular-redux/store';
import { Observable ,  Subscription } from 'rxjs';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { SidebarActions } from '../../actions/index';
import { ApplicationService } from '../../services/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { ChangeManagementService } from '../../../common/services/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { LeftSidebarManagementService } from '../../services/index';
import * as domUtils from '../../../common/utils/domUtils';
import { NotificationType } from '../../models/notification-type.enum';

@Component({
  moduleId: module.id,
  selector: 'slx-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @select(['sidebar', 'isRightSidebarOpen'])
  public isRightSidebarOpen: Observable<boolean>;

  @select(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  public notificationTypes: any;

  public state: {
    isRightSidebarOpen: boolean;
    isLeftSidebarOpen: boolean;
  };
  public isHiddenLeftSidebar: boolean;
  public isHiddenOrgLevelBreadcrumb: boolean;
  public actualLeftSidebarState: boolean;
  public keepOrgLevelBreadcrmb: boolean;

  public appConfig: IApplicationConfig;

  @unsubscribe()
  private isLeftSidebarOpenSubscripion: Subscription;
  @unsubscribe()
  private isRightSidebarOpenSubscripion: Subscription;
  @unsubscribe()
  private routeSubscripion: Subscription;
  @unsubscribe()
  private appSubscripion: Subscription;
  @unsubscribe()
  private leftSidebarSubscripion: Subscription;
  private get isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }

  constructor(
    private sidebarActions: SidebarActions,
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private changeManagementService: ChangeManagementService,
    private leftSidebarService: LeftSidebarManagementService,
    private renderer: Renderer2
   ) {
    this.notificationTypes = NotificationType;
    this.state = {
      isLeftSidebarOpen: false,
      isRightSidebarOpen: false
    };
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    scheduleMicrotask(() => {
      this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe((value: boolean) => this.state.isLeftSidebarOpen = value);
      this.isRightSidebarOpenSubscripion = this.isRightSidebarOpen.subscribe((value: boolean) => this.state.isRightSidebarOpen = value);

      this.routeSubscripion = this.route.url.pipe(
        combineLatest(this.route.queryParams))
        .subscribe(([urslSegments, params]: [UrlSegment[], Params]): void => {
          this.actualLeftSidebarState = !!params['hideLeftSideBar'];
          this.keepOrgLevelBreadcrmb = !!params['keepOrgLevelBreadcrmb'];
          if (!this.leftSidebarService.isAlwaysShowMode) {
            this.updateLeftSideBarState(this.actualLeftSidebarState);
          }
        });

      this.leftSidebarSubscripion = this.leftSidebarService.leftSidebarMode$.subscribe((isAlwaysShowMode: boolean): void => {
        this.updateLeftSideBarState(isAlwaysShowMode ? false : this.actualLeftSidebarState);
      });

      if (this.isMobile) {
        this.sidebarActions.setLeftSidebar(false);
        this.sidebarActions.setRightSidebar(false);
        this.state.isRightSidebarOpen = false;
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public get isShowSecondLine(): boolean {
    return _.some(this.route.parent.children, (route: ActivatedRoute) => {
      const showSecondLine: boolean = _.get(route, 'snapshot.data.showSecondLine');

      return _.isBoolean(showSecondLine) && showSecondLine === true && route.outlet === 'primary';
    });
  }

  public updateLeftSideBarState(isHidden: boolean): void {
    this.isHiddenLeftSidebar = isHidden;
    this.isHiddenOrgLevelBreadcrumb = !this.keepOrgLevelBreadcrmb && this.isHiddenLeftSidebar;
    this.leftSidebarService.updateState(this.isHiddenLeftSidebar, this.isMobile);
  }

  public toggleRightSidebar(): void {
    this.sidebarActions.setRightSidebar(!this.state.isRightSidebarOpen);
  }

  public toggleLeftSidebar(): void {
    this.sidebarActions.setLeftSidebar(!this.state.isLeftSidebarOpen);
  }
}
