import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, ConfirmOptions, ConfirmDialogComponent } from '../../../common/index';
import { mutableSelect, destroyService, unsubscribe } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';

import { ColorUtil } from '../../../common/utils/color-util';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { appMessages, IApplicationMessages } from '../../../app.messages';

import { AccessManagementService, AbsenceConfigurationApiService } from '../../services/index';
import { AbsenceModel, EditableListActionKind } from '../../models/index';
import { EditableListComponent } from '../../components/index';
import { NotificationsService } from '../../../core/components/index';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { AbsencesConfigurationManagementService } from '../../services/absences/absence-configuration-management.service';
import { NgForm } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-absences',
  templateUrl: 'absences.component.html',
  styleUrls: ['absences.component.scss'],
  providers: [AbsencesConfigurationManagementService, AccessManagementService]
})
export class AbsencesComponent implements OnInit, OnDestroy {

  public onAddItem: () => void;
  public onDeletedItems: () => void;

  public crudHelper: ConfigurationComponentHelper<AbsenceModel>;
  public gridState: KendoGridStateHelper<AbsenceModel>;
  public selectionHelper: KendoGridCustomSelectionHelper<AbsenceModel>;

  public state: {
    isLoading: boolean;
    configureMode: boolean;
    copyMode: boolean;
  };

  public appConfig: IApplicationConfig;
  public appMessages: IApplicationMessages;

  public prohibitedNameValues: string[] = [];

  @ViewChild('kendoGrid', {static: true})
  private set grid(value: GridComponent) {
    if (this.crudHelper) this.crudHelper.grid = value;
    this.m_grid = value;
  }
  private get grid(): GridComponent {
    return this.m_grid;
  }
  private m_grid: GridComponent;

  @destroyService()
  private management: AbsencesConfigurationManagementService;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private editSubscription: Subscription;
  @unsubscribe()
  private removeSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private mainFormSubscription: Subscription;

  @ViewChild('templateForm', { static: true })
  private mainForm: NgForm;

  constructor(
    management: AbsencesConfigurationManagementService,
    public access: AccessManagementService,
    private modalService: ModalService
  ) {

    this.management = management;

    this.gridState = new KendoGridStateHelper<AbsenceModel>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

    this.crudHelper = new ConfigurationComponentHelper<AbsenceModel>();
    this.crudHelper.gridState = this.gridState;
    this.crudHelper.selectionHelper = this.selectionHelper;
    this.crudHelper.management = management;

    // directive intercepted methods (scope bug)
    this.onAddItem = () => {
      let absence: AbsenceModel = new AbsenceModel();
      this.updateProhibitedNameValues(absence);
      this.crudHelper.addItem(absence);
    };

    this.onDeletedItems = () => {
      this.crudHelper.deleteSelected();
    };
  }

  public ngOnInit(): void {

    this.appMessages = appMessages;
    this.appConfig = appConfig;

    this.state = {
      isLoading: false,
      configureMode: true,
      copyMode: false
    };

    this.stateSubscription = this.management.onStateChanged$.subscribe((state: { isLoading: boolean, configureMode: boolean, copyMode: boolean }) => {
      if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
      if (_.has(state, 'configureMode')) this.state.configureMode = state.configureMode;
      if (_.has(state, 'copyMode')) this.state.copyMode = state.copyMode;
    });

    this.editSubscription = this.management.editItemCmd$.subscribe((absence: AbsenceModel) => {
      if (absence) {
        this.updateProhibitedNameValues(absence);
        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
          if (this.mainForm.dirty) {
            this.management.markAsDirty();
          }
        });
      } else {
        if (this.mainFormSubscription) {
          this.mainFormSubscription.unsubscribe();
        }
      }
    });

    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: AbsenceModel[]): void => {
      this.crudHelper.selectionChange(_.first(records), 0);
    });

    this.removeSubscription = this.management.removeItemsCmd$.subscribe((item: AbsenceModel) => {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      ConfirmDialogComponent.openDialog(
        'Confirmation',
        'Do you want to delete the absence?',
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.management.doRemoveItem(item);
          }
        }, options);
    });

    this.crudHelper.grid = this.grid;
    this.crudHelper.init();
    this.management.init();
  }

  // Must be, see #issueWithAOTCompiler
  public ngOnDestroy(): void {
    if (this.crudHelper) {
      this.crudHelper.destroy();
    }
  }

  public updateProhibitedNameValues(absence: AbsenceModel): void {
    if (absence) {
      let values: string[] = [];
      _.each(this.management.container.records, (s: AbsenceModel) => {
        if (absence.code !== s.code) {
          values.push(s.code);
        }
      });
      this.prohibitedNameValues = values;
    }
  }

  public onColorPickerChange(absence: AbsenceModel, colorCode: string): void {
    if (absence) absence.color = ColorUtil.HexToDec(colorCode);
  }

  public getColor(absence: AbsenceModel): string {
    return absence ? ColorUtil.DecToHexString(absence.color, true) : '#ffffff';
  }

  public onMobileRendererEvent(action: EditableListActionKind, item: AbsenceModel, index: number): void {
    if (action === EditableListActionKind.SELECTION_CHANGE) {
      this.crudHelper.selectionChange(item, index);
    } else if (action === EditableListActionKind.START_EDIT) {
      this.crudHelper.startEdit(item, index);
    }
  }

  public onMobileEditorEvent(action: EditableListActionKind, item: AbsenceModel, index: number): void {
    if (action === EditableListActionKind.COMPLETE_EDIT) {
      this.crudHelper.completeEdit(item, index);
    } else if (action === EditableListActionKind.CANCEL_EDIT) {
      this.crudHelper.cancelEdit(index);
    }
  }

  public validateLoa(dataitem: AbsenceModel): void {
    if (dataitem.loaIndicator) {
      const checkedAbsence = this.management.container.records.filter(
        (absence: AbsenceModel) => absence.loaIndicator === true
      );
      if (checkedAbsence.length > 1) {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Warning', `Only 1 absence code can be checked and appear on Schedules for employees on a leave of absence. Please uncheck the current LOA absence code if you wish to change how an LOA appears on the Schedule for employees.`,
          this.modalService,
          (result: boolean) => {           
            dataitem.loaIndicator = false;          
        },
          options
        );
      }
    }
  }
}
