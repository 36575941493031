import { Component, Input, Output, OnInit, OnDestroy, ChangeDetectionStrategy, EventEmitter, ChangeDetectorRef } from '@angular/core';

import 'moment-range';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription ,  Observable } from 'rxjs';
import { GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor, GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { ModalService, StateManagementService } from '../../../../common/services/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ScheduleEntryApiService, ShiftReplacementApiService, ShiftReplacementManagementService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { mutableSelect } from '../../../../core/decorators/redux-decorators';
import { IColumnSettings } from '../../../../core/models/index';
import {
  ShiftReplacementSettings, ShiftReplacementInfo, ShiftEligibleEmployee, ShiftSmsNotificationResponse, ShiftAppNotificationResponse, ScheduleFilterConfig
} from '../../../models/index';
import { screenUtils, IScreenUtils } from '../../../../common/utils/index';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { IconAccess } from '../../../../portal/models/messages/icon-access.model';
import { NotificationsApiService } from '../../../../portal/services/notifications/notifications-api.service';
import { OrgLevel } from '../../../../state-model/models/index';


@Component({
  moduleId: module.id,
  selector: 'slx-shift-replacement-grid',
  templateUrl: 'shift-replacement-grid.component.html',
  styleUrls: ['shift-replacement-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftReplacementGridComponent implements OnInit, OnDestroy {
  isModifyPayperiodEnabled: boolean = false;

  @Input()
  public set shiftReplacementInfo(shiftReplacementInfo: ShiftReplacementInfo) {
    this.gridData = shiftReplacementInfo;
    if (this.gridData) {
      this.generateFilters(this.gridData);
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }

  @Input('settings')
  public set replacementSettings(settings: ShiftReplacementSettings) {
    this.settings = settings;
    this.columnsSettings = _.keyBy(settings.columns, (column: IColumnSettings) => {
      return column.name;
    });
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  @Output()
  public selectionRowChanged: EventEmitter<ShiftEligibleEmployee[]>;

  public appConfig: IApplicationConfig;
  public columnsSettings: StringMap<IColumnSettings>;
  public isAllSelected: boolean;
  public settings: ShiftReplacementSettings;
  public screenUtils: IScreenUtils;
  public gridView: GridDataResult;
  public gridState: State;
  public lastSelectedRecord: ShiftEligibleEmployee;
  public selectedEmployees: ShiftEligibleEmployee[];
  public originalRecords: ShiftEligibleEmployee[];
  public checkedEmployees: ShiftEligibleEmployee[];
  public dataLoaded: boolean = false;
  public sendSmsModeOn: boolean;
  public isMessageCenterEnabled: boolean = false;
  public typeFilters: any[];

  private gridData: ShiftReplacementInfo;
  @unsubscribe()
  private checkedEmployeesSubscription: Subscription;
  @unsubscribe()
  private changedSmsModeSubscription: Subscription;
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<any>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  public orgLevel: OrgLevel;

  constructor(
    private modalService: ModalService,
    private shiftReplacementApiService: ShiftReplacementApiService,
    private changeDetector: ChangeDetectorRef,
    private stateManagement: StateManagementService,
    private shiftRepService: ShiftReplacementManagementService,
    private scheduleEntryApiService : ScheduleEntryApiService,
    private notificationApiService: NotificationsApiService
  ) {
    this.gridState = {
      skip: undefined,
      take: Infinity,
      filter: undefined,
      sort: [], //remove default filter, see #40209 { field: 'weeklyProjectedHours', dir: 'asc' }, { field: 'dateHired', dir: 'asc' }
      group: undefined
    };
    this.columnsSettings = {};
    this.selectionRowChanged = new EventEmitter<ShiftEligibleEmployee[]>();
    this.screenUtils = screenUtils;
    this.appConfig = appConfig;
    this.sendSmsModeOn = false;
    this.selectedEmployees = [];
    this.checkedEmployees = [];
    this.shiftRepService.isModifyApprovedPayperiod$.subscribe(result=>{
      this.isModifyPayperiodEnabled = result;
    })
  }

  public ngOnInit(): void {
    this.checkedEmployeesSubscription = this.shiftRepService
      .subscribeToCheckedEmployees((checkedEmployees: ShiftEligibleEmployee[]) => {
        const hasChanged: boolean = !(_.get(this.checkedEmployees, 'length') === _.get(checkedEmployees, 'length'));
        if (hasChanged) {
          this.updateCheckedEmployees(checkedEmployees) ;
        }
      });
    this.changedSmsModeSubscription = this.shiftRepService
      .subscribeToChangeSmsMode((smsModeOn: boolean) => {
        this.sendSmsModeOn = smsModeOn;
        this.clearCheckedEmployees();
        this.changeDetector.detectChanges();
      });
      this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
        if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
          this.orgLevel = orgLevel;
          this.checkMessageCenterAccess();
        }
      });

  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public refresh(): void {
    this.refreshGrid();
    this.filterChange(null);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.sort = sort;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public filterChange(filter: any): void {
    const filteredRecords = this.gridView.data.filter(
      (record: ShiftEligibleEmployee) => record.canReceiveSms && !this.validate(record)
    );
    this.isAllSelected = filteredRecords.length > 0 && filteredRecords.every(
      (record: ShiftEligibleEmployee) => record.isChecked
    );
  }

  public onPutCheck(isCheckedAll: boolean): void {
    let checkedEmployees: ShiftEligibleEmployee[] = _.filter(this.originalRecords, (record: ShiftEligibleEmployee) => {
      if (isCheckedAll) {
        record.isChecked = this.isAllSelected;
      }

      return record.isChecked;
    });
    this.checkedEmployees.length = 0;
    this.checkedEmployees.push(...checkedEmployees);
    this.shiftRepService.changeCheckedEmployees(this.checkedEmployees);
  }

  public onSelectAll(): void {
    this.gridView.data.forEach((record: ShiftEligibleEmployee) => {
      if (record.canReceiveSms && !this.validate(record)) {
        record.isChecked = this.isAllSelected;
      }
    });
    this.onPutCheck(false);
  }

  public onChildCheckboxChange(): void {
    this.filterChange(null);
    this.onPutCheck(false);
  }

  public clearCheckedEmployees(): void {
    this.checkedEmployees.length = 0;
    this.isAllSelected = false;
    this.gridView.data.forEach((record: ShiftEligibleEmployee) => {
      record.isChecked = false;
    });
    this.shiftRepService.changeCheckedEmployees(this.checkedEmployees);
  }

  public onCellClick(clickEvent: CellClickEvent): void {
    const clickedRecord: ShiftEligibleEmployee = this.gridView.data[clickEvent.rowIndex];
    if(this.isModifyPayperiodEnabled)
    this.shiftRepService.replaceEmployee.selectedEmployee = clickedRecord;
    this.shiftRepService.replaceEmployeeId = clickedRecord.employee.id;
    let startDate = moment(this.shiftRepService.replaceEmployee.dateOn).format(`MM-DD-YYYY`);
    this.scheduleEntryApiService.checkApprovedPayperiod(this.shiftRepService.replaceEmployeeId.toString(),startDate,startDate).then((data: any) => {
      this.shiftRepService.replaceApprovedResult.push(data);
    })

    if (_.isObject(clickedRecord)) {
      if (clickedRecord.isSelected) {
        const index: number = _.findIndex(this.selectedEmployees, (record: ShiftEligibleEmployee) => record.employee.id === clickedRecord.employee.id);
        clickedRecord.isSelected = false;
        this.selectedEmployees.splice(index, 1);
      } else {
        if (!this.settings.isMultipleSelectionMode) {
          this.selectedEmployees.length = 0;
          _.forEach(this.gridView.data, (record: ShiftEligibleEmployee) => { record.isSelected = false; });
        }

        clickedRecord.isSelected = true;
        this.selectedEmployees.push(clickedRecord);
      }

      this.shiftRepService.changeSelectedEmployees(this.selectedEmployees, this.settings.isMultipleSelectionMode);
    }
  }

  public isRowSelected(): (row: RowArgs) => boolean {
    return (row: RowArgs) => (
      _.findIndex(this.selectedEmployees, (record: ShiftEligibleEmployee) =>
        (record.employee.id === row.dataItem.employee.id)
        && (record.position.id === row.dataItem.position.id)) !== -1
    );
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public getColor(item: ShiftEligibleEmployee): any {
    if (this.settings.request.showAgencyEmployees && item.isAgency) return { 'color': '#f68d2e', 'font-weight': 'bold' }; // '$theme-main-accent'
    if (this.settings.request.showAvailabilityRecords && item.isAvailable) return { 'color': 'purple', 'font-weight': 'bold' }; //$theme-purple'
    if (this.settings.request.showEmployeesOtherShifts && item.isWorkingOtherShift) return { 'color': '#0047bb', 'font-weight': 'bold' };//'$theme-blue'
    if(this.settings.request.showDayOffEmployees && item.isDayOff) return { 'color': '#000000', 'font-weight': 'bold' }; //'$theme-light-green'
    return { 'color': '#736969' }; //$theme-black
  }

  public onSettingsChanged(): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public isDisplayed(columnName: string): boolean {
    const prop: string = `${columnName}.displayed`;

    return this.dataLoaded && !!_.get(this.columnsSettings, prop, false);
  }

  public isNumber(value: string): boolean {
    const parsed = parseFloat(value);
    return parsed >= 0;
  }

  private updateCheckedEmployees(checkedEmployees: ShiftEligibleEmployee[]): void {
    if (this.gridView && this.gridView.data) {
      this.isAllSelected = false;
      _.forEach(this.gridView.data, (record: ShiftEligibleEmployee) => {
        const employee: ShiftEligibleEmployee = _.find(
          checkedEmployees,
          (emp: ShiftEligibleEmployee) => record.employee.id === emp.employee.id
        );
        record.isChecked = _.isObject(employee);
      });
      this.changeDetector.detectChanges();
    }
  }

  private refreshGrid(): void {
    if (!this.gridData) {
      this.gridView = null;
      return;
    }
    let filteredRecords: ShiftEligibleEmployee[] = _.filter(this.gridData.records, (record: ShiftEligibleEmployee) => {
      return this.settings.request.showDayOffEmployees && record.isDayOff ||
        this.settings.request.showAgencyEmployees && record.isAgency ||
        this.settings.request.showAvailabilityRecords && record.isAvailable ||
        this.settings.request.showEmployeesOtherShifts && record.isWorkingOtherShift;
    });
    this.removeSelectedIfNotInFilteredRecords(filteredRecords);
    this.originalRecords = filteredRecords;
    let tempData: GridDataResult = process(filteredRecords, this.gridState);
    let sortedRecords: ShiftEligibleEmployee[] = orderBy(tempData.data, this.gridState.sort);
    let groupedRecords: ShiftEligibleEmployee[] | GroupResult[] = groupBy(sortedRecords, this.gridState.group);

    this.gridView = {
      data: groupedRecords,
      total: groupedRecords.length
    };
    this.dataLoaded = true;
  }

  private generateFilters(shiftReplacementInfo: ShiftReplacementInfo): void {
    if (shiftReplacementInfo) {
      this.typeFilters = ScheduleFilterConfig.optInStatusFilters();
    }
  }

  public getOptInTooltip(empOptIn: number): string {
    if (empOptIn === 0) return 'Opted Out';
    if (empOptIn === 1) return 'Opted In';
    if (empOptIn === 2) return 'Not Responded to the Opt In Request';
    return null;
  }

  public async checkMessageCenterAccess(): Promise<void> {
    const access: IconAccess = await this.notificationApiService.getIconAccessCheck(this.orgLevel.id);
    this.isMessageCenterEnabled = access.isMessageCenterEnabled;
  }

  public validate(dataItem: ShiftEligibleEmployee): boolean {
    if (!this.isMessageCenterEnabled) return false;
    if (!dataItem.canSendSms) return true;
    if (dataItem.canSendSms) return false;
    if (dataItem.empOptIn === 1) return false;
    return true;
  }

  private removeSelectedIfNotInFilteredRecords(filteredRecords: ShiftEligibleEmployee[]): void {
    let selectedRecords: ShiftEligibleEmployee[] = _.intersectionBy(this.selectedEmployees, filteredRecords, x => x.employee.id);
    this.selectedEmployees = selectedRecords;
    this.shiftRepService.changeSelectedEmployees(this.selectedEmployees, this.settings.isMultipleSelectionMode);
  }
}
