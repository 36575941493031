
import {Observable , combineLatest, Subject ,  Subscription } from 'rxjs';
import {tap, filter, map} from 'rxjs/operators';
import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router, UrlTree } from '@angular/router';
import html2canvas from 'html2canvas-pro';
import * as jspdf from 'jspdf';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ISession } from '../../../authentication/store/index';
import { appConfig } from '../../../app.config';
import {
  ConfirmDialog2Component,
  ConfirmOptions,
  DeviceDetectorService,
  PositionMappingNavigationService,
  PostScheduleNavigationService,
  SoConsoleNavigationService,
  ToolbarSectionTypes,
} from '../../../common/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { screenUtils } from '../../../common/utils';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { LocationUnit, Lookup, LookupType, Position, PositionGroup, Shift } from '../../../organization/models/index';
import { LookupService, ScheduleCycleHelperService } from '../../../organization/services/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import {
  DAUSummaryDetails,
  DetailField,
  DetailGroup,
  DetailGroupViewSettingsTypes,
  DetailRow,
  Details,
  DetailsAgencyPosition,
  DetailsPartner,
  GroupDetailsData,
  OpenShiftDetails,
  ShiftAddEmployeeCmd,
  ShiftReplaceCmd,
  ShiftReplacementRequest,
} from '../../models/index';
import {
  DetailScreenMapService,
  DetailScreenService,
  ScheduleApiService,
  ScheduleEntryApiService,
  ScheduleEntryManagementService,
  ShiftReplacementApiService,
  ShiftReplacementManagementService,
} from '../../services/index';
import { ShiftRequestService } from '../../services/schedule/shift-request.service';
import { EmployeeAddShiftOpenComponent, ShiftReplacementReplaceComponent } from '../shift-replacement/index';
import { ComponentStateStorageService } from '../../../common/services/component-state/component-state-storage.service';
import { DailyUnitAssignmentNavigationService } from '../../../common/services/navigation/daily-unit-assignment-navigation.service';
import { IndividualScheduleNavigationService } from '../../../common/services/navigation/individual-schedule-navigation.service';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { IControlState, StateResetTypes } from '../../../core/models/settings/index';
import { ScheduleCycle } from '../../../organization/models/lookup/schedule-cycle';
import { ShiftDefinition } from '../../../organization/models/lookup/shift-definition';
import { OrgLevelWatchService } from '../../../organization/services/org-level/org-level-watch.service';
import { DailyUnitGridEmployee } from './daily-unit-grid-employees/daily-unit-grid-employees-model';
import { DailyUnitGridEmployeesComponent } from './daily-unit-grid-employees/daily-unit-grid-employees.component';
import { AgencyMapping } from '../../../configuration/models';
import { AgenciesApiService,PartnerConfigManagementService } from '../../../configuration/services';
import { AppSettingsManageService } from '../../../app-settings/services';
import { CancelType, CancelTypeList } from '../../models/detail-screen/cancel-type';
import { ConfirmDialog3Component, ConfirmOptions3 } from '../../../common/components/confirm-dialog3/confirm-dialog3.component';
import { PendingShiftValue } from '../../models/daily-unit-assignment/add-open-shift';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
import { DailyUnitViewSettingsPopupComponent } from './daily-unit-view-settings-popup/daily-unit-view-settings-popup.component';
import { scheduleMicrotask } from '../../../core/utils';
import { DailyUnitFilterDialogComponent } from './daily-unit-filter-dialog/daily-unit-filter-dialog.component';
import { DailyUnitFilterOptions } from '../../models/daily-unit-assignment/daily-unit-filter-options';

const imageCache: StringMap<string> = {};
const VIEW_MODE_KEY: string = 'view_mode';
const FILTERS: string = 'filters';
const DATE: string = 'date';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-unit-grid',
  templateUrl: 'daily-unit-grid.component.html',
  styleUrls: ['daily-unit-grid.component.scss'],
  providers: [StateManagementService],
})
export class DailyUnitGridComponent implements OnInit, OnDestroy {
  public agencyMap: any;
  isLoading: boolean = false;
  public requestShift: boolean = false;
  public enableopenshiftonfly: boolean = false;
  public pendingShiftValue: PendingShiftValue[]=[];
  public loading: boolean = false;
  public approvedPayPeriod: boolean = false;
  public addShiftEmployee: ShiftAddEmployeeCmd;
  public isModifyPayPeriodApproved: boolean = false;
  public startDate: string;
  public endDate: string;
  public isOrglevelPayPeriodApproved: boolean = false;
  @unsubscribe()
  private isLeftSidebarOpenSubscripion: Subscription;
  public isActionsOpened: boolean;

  @Input()
  public set dateOn(value: Date) {
    this.m_dateOn = value;
  }

  @Input()
  public shiftGroup: boolean;

  public get dateOn(): Date {
    return this.m_dateOn;
  }

  @ViewChild('popupRef') popupRefChild: DailyUnitGridEmployeesComponent;

  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  @unsubscribe()
  private userSubscription: Subscription;

  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;

  public details: Details;

  public state: {
    isLoading: boolean;
    isSelectPropagated: boolean;
    switchShiftMode: boolean;
    isLeftSidebarOpen : boolean;
  };
  public dauSummaryDetails: DAUSummaryDetails;
  public currentOrgLevel: OrgLevel;
  public previousGroups: DetailGroup[];
  public previousDayDetails: DetailGroup[];
  public nextDayDetails: DetailGroup[];
  public filtersOn: boolean;
  public restrictedInfo: string[];
  public rowForShiftChange: DailyUnitGridEmployee;

  public positionGroupFilter: PositionGroup[];
  public posFilter: Position[];
  public unitFilter: LocationUnit[];
  public shiftFilter: Shift[];

  public positionGroupLookup: Lookup;
  public positionLookup: Lookup;
  public unitsLookup: Lookup;
  public shiftsLookup: Lookup;
  public currentShifts: ShiftDefinition[];

  public currentViewMode: DetailGroupViewSettingsTypes;
  public filtersApplied: boolean = false;

  private prePositionGroupFilter: PositionGroup[];
  private prevPosFilter: Position[];
  private prevUnitFilter: LocationUnit[];
  private prevShiftFilter: Shift[];
  private m_dateOn: Date;
  private pathPositionGroupIds: number[];
  private pathPositionIds: number[];
  private unitIds: number[];
  private shiftIds: number[];
  public cancelTypeListData: CancelTypeList[];
  public onLoad: boolean = false;

  private scheduleCycleHelperService: ScheduleCycleHelperService;
  private detailScreenService: DetailScreenService;
  private scheduleApiService: ScheduleApiService;
  private detailScreenMapService: DetailScreenMapService;
  public positionTabs: string[] = []

  public loadDetailsEvent: Subject<any> = new Subject<any>();
  public filtersChanged: any[] = [];

  @unsubscribe()
  private detailsSubscription: Subscription;
  @unsubscribe()
  private dateSubscription: Subscription;
  @unsubscribe()
  private notDepartmentSubscription: Subscription;
  @unsubscribe()
  private routeSubscripion: Subscription;
  @unsubscribe()
  public orgLevelSubscripion: Subscription;

  public eventSubscription: Subscription;

  private modalService: ModalService;
  public activatedRoute: ActivatedRoute;
  public router: Router;
  private shiftReplacementApiService: ShiftReplacementApiService;
  private domSanitizer: DomSanitizer;

  private selectedPositionGroupIds: number[];
  private selectedPositionIds: number[];
  private selectedUnitIds: number[];
  private selectedShiftIds: number[];

  private changeDetector: ChangeDetectorRef;
  private soNavService: SoConsoleNavigationService;
  private individualNavService: IndividualScheduleNavigationService;
  private groupTable: Element;
  private groupContainer: HTMLElement;
  private scrollToValue: number = 0;
  public IsEnabled: boolean = false;
  public agencyData: DetailsPartner;
  public agencyListData: DetailsPartner[] = [];
  public partnerPositions: DetailsAgencyPosition[];
  public alias: string;
  private positionMappingNavigationService: PositionMappingNavigationService;
  private postScheduleNavigationService: PostScheduleNavigationService;
  private requestShiftIsEnabled: boolean = false;

  public schedTotal: number = 0;
  public schedIdealTotal: number = 0;
  public dayTotal: number = 0;
  public dayIdealTotal: number = 0;
  public eveningTotal: number = 0;
  public eveningIdealTotal: number = 0;
  public nightTotal: number = 0;
  public nightIdealTotal: number = 0;

  public agencyTotalShifts: number = 0;
  public agencyNightShifts: number = 0;
  public agencyEveningShifts: number = 0;
  public agencyDayShifts: number = 0;

  public switchTotalShift: number = 0;
  public switchDayShift: number = 0;
  public switchEveningShift: number = 0;
  public switchNightShift: number = 0;

  public openTotalShifts: number = 0;
  public openDayShifts: number = 0;
  public openEveningShifts: number = 0;
  public openNightShifts: number = 0;

  public overTimeTotalShifts: number = 0;
  public overTimeDayShifts: number = 0;
  public overTimeEveningShifts: number = 0;
  public overTimeNightShifts: number = 0;


  public openShiftToggle: boolean = false;
  public pendingShiftToggle: boolean = false;
  public switchShiftToggle: boolean = false;
  public overTimeShiftToggle: boolean = false;
  public cancelType: CancelType[];
  public buttonNum: number = 0;
  public isPositionMapped: boolean = true;
  public isPartnerIntegrations: boolean = false;
  public screenWidth: number;
  public navigateService: DailyUnitAssignmentNavigationService
  constructor( public scheduleEntryApiService: ScheduleEntryApiService,
    scheduleApiService: ScheduleApiService,
    detailScreenService: DetailScreenService,
    modalService: ModalService,
    activatedRoute: ActivatedRoute,
    router: Router,
    public  shiftReplacementService: ShiftReplacementManagementService,
    shiftReplacementApiService: ShiftReplacementApiService,
    scheduleCycleHelperService: ScheduleCycleHelperService,
    private lookupService: LookupService,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private orgLevelService: OrgLevelWatchService,
    private shiftRequestService: ShiftRequestService,
    public management: ScheduleEntryManagementService,
    private agenciesApiService: AgenciesApiService,
    private appSettingsManageService: AppSettingsManageService,
    private readonly partnerConfigManagementService: PartnerConfigManagementService
  ) {
    this.isTablet();
    this.isMobile();
    this.getScreenSize();
    this.scheduleEntryApiService = scheduleEntryApiService;
    this.scheduleApiService = scheduleApiService;
    this.detailScreenService = detailScreenService;
    this.modalService = modalService;
    this.activatedRoute = activatedRoute;
    this.router = router;
    this.shiftReplacementApiService = shiftReplacementApiService;
    this.scheduleCycleHelperService = scheduleCycleHelperService;
    this.restrictedInfo = ['ShiftGroup'];

    this.selectedPositionGroupIds = [];
    this.selectedPositionIds = [];
    this.selectedShiftIds = [];
    this.selectedUnitIds = [];
    this.agencyMap = [];
    this.agencyListData = [];
    this.navigateService = new DailyUnitAssignmentNavigationService(this.router, this.activatedRoute);
    this.individualNavService = new IndividualScheduleNavigationService(this.router, this.activatedRoute);
    this.soNavService = new SoConsoleNavigationService(this.router, this.activatedRoute);
    this.positionMappingNavigationService = new PositionMappingNavigationService(this.router, this.activatedRoute);
    this.postScheduleNavigationService = new PostScheduleNavigationService(this.router, this.activatedRoute);
    this.state = {
      isLoading: false,
      isSelectPropagated: true,
      switchShiftMode: false,
        isLeftSidebarOpen : false,
    };

    this.eventSubscription = this.shiftRequestService.getClickEvent().subscribe(() => {
      this.state.isLoading = true;
      this.detailScreenService
        .getDailyUnits(this.orgLevel, this.dateOn)
        .then((response) => {
          this.details = response;
          this.applyFilters();
          this.categoriesDetails();
          this.state.isLoading = false;
        })
        .catch((err: any) => {
          this.state.isLoading = false;
        });
    });
    this.buttonNum = 0;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
  }

  public ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.buttonNum = 0;
    this.userSubscription = this.user$.subscribe((session: ISession) => {
      if (session) {
        this.alias = session.alias;
      }
    });
    this.stateManagement.init('DailyUnitComponent', true);
    this.state.isLoading = true;
    this.shiftGroup = true;
    let dateOnEvent$: Observable<Date> = this.activatedRoute.params.pipe(map(({ date: dateOn }: Params) => {
      let d: Date = moment(dateOn, appConfig.linkDateFormat).toDate();
      if (!dateOn) {
        return this.getSavedDate();
      } else {
        this.saveDate(d);
      }
      return d;
    }));
    this.routeSubscripion = combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .subscribe(([params, queryParams]) => {
        let posGroupIds: string = queryParams['positionGroupId'];
        if (posGroupIds) this.pathPositionGroupIds = _.map(posGroupIds.split(','), (item: string) => +item);
        let posIds: string = queryParams['positionId'];
        if (posIds) this.pathPositionIds = _.map(posIds.split(','), (item: string) => +item);
        let unitIds: string = queryParams['unitId'];
        if (unitIds) this.unitIds = _.map(unitIds.split(','), (item: string) => +item);
        let shiftIds: string = queryParams['shiftId'];
        if (shiftIds) this.shiftIds = _.map(shiftIds.split(','), (item: string) => +item);
        this.setViewMode(queryParams[VIEW_MODE_KEY]);
      });

    this.dateSubscription = dateOnEvent$.subscribe((dateOn: Date) => (this.dateOn = dateOn));
    this.orgLevelSubscripion = this.orgLevel$.subscribe((selectedOrgLevel: OrgLevel) => {
      this.orgLevel = selectedOrgLevel;
      // get application settings
      this.appSettingsManageService.getAppServerConfig()
        .then((appConfig) => {
          this.isPartnerIntegrations = appConfig.partnerintegrations;
          this.enableopenshiftonfly = appConfig.enableOpenShiftOnFly;
          // Update agency information
          if (this.isPartnerIntegrations) {
            this.getPartnerAgenciesPositions(this.orgLevel);
          }
        });
    });

    let orgLevelChangeEvent$: Observable<OrgLevel> = this.orgLevel$.pipe(filter(
      (selectedOrgLevel: OrgLevel) => selectedOrgLevel.type === OrgLevelType.department
    ));

    let detailsEvent$: Observable<[OrgLevel, Date, any]> = combineLatest(
      orgLevelChangeEvent$,
      dateOnEvent$,
      this.loadDetailsEvent
    ).pipe(tap(() => (this.state.isLoading = true)));

    this.detailsSubscription = detailsEvent$.subscribe(([selectedOrgLevel, dateOn]: [OrgLevel, Date, any]) => {
    
      if (!moment(dateOn).isValid()) {
        return;
      }
      this.isLoading = true;
      this.requestShiftIsEnabled = true;
      let currentDate = moment().format(appConfig.dateFormat);
      let seletedDate = moment(dateOn).format(appConfig.dateFormat);
      if (!moment(currentDate).isAfter(seletedDate)) {
        this.requestShiftIsEnabled = false;
      }
      this.loading = true;
      this.loadDetails(selectedOrgLevel, dateOn)
        .then((d: Details) => {
          this.details = d;
          if (this.details) {
            this.previousGroups = _.cloneDeep(this.details.groups);
          }
          this.currentOrgLevel = selectedOrgLevel;
          this.getShiftState(d);
          this.applyFilters();
          this.categoriesDetails();
          this.restoreGroupsStates(this.previousGroups); //always after filters!
          this.isLoading = false;
        })
        .catch((error: any) => console.log(error))
        .then(() => (this.state.isLoading = false,this.loading = false,
          this.isLoading = false));

          this.loadDetails(selectedOrgLevel, moment(dateOn).subtract(1, 'day').toDate())
          .then((d: Details) => {
            this.previousDayDetails = _.cloneDeep(d.groups);
          }).catch((error: any) => console.log(error))
  
        this.loadDetails(selectedOrgLevel, moment(dateOn).add(1, 'day').toDate())
          .then((d: Details) => {
            this.nextDayDetails = _.cloneDeep(d.groups);
          }).catch((error: any) => console.log(error))

      this.loadFilterLookups(selectedOrgLevel.id);
    });
    this.isLoading = false;
    this.notDepartmentSubscription = this.orgLevel$.pipe(
      filter((o: OrgLevel) => o.type !== OrgLevelType.department))
      .subscribe(() => {
        this.soNavService.navigateToSoConsole();
      });
    this.loadDetailsEvent.next();
    this.routeSubscripion = this.shiftRequestService.redirectPositionMappingEvent().subscribe(() => {
      this.positionMappingNavigationService.navigateToPositionMapping(this.currentOrgLevel.id);
    });
    this.shiftRequestService.getopenShiftWindowStatus().subscribe((e) =>{
      this.loadDetailsEvent.next();
    });
    this.getSettings();
  }
  public getShiftState(details:Details):void {
this.pendingShiftValue =[];
    details.groups.forEach(group=>{
      group.rows.forEach(element => {
        element.fields.forEach(el => {
          if(el.name == 'PartnerId' && el.value != '' ){
           let isAssigned =  element.fields.filter(e => e.name == 'EmpName' && e.value =='')
            if(isAssigned.length>0){
                this.getValues(element.fields);
              }
          }
        });
      });
    });
    this.shiftRequestService.pendingShiftDetails= this.pendingShiftValue;
    this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe((value: boolean) => {
      scheduleMicrotask(() => { this.state.isLeftSidebarOpen = value; });
    });
  }

  public getValues(element) :void {
   let pendingShift:PendingShiftValue = new PendingShiftValue;
    let position,shift,unit
    element.forEach(el => {
      if(el.name =="JobCode") {
        position = el.value;
      }
      else if(el.name == "ShiftName") {
        shift = el.value;
      }
      else if(el.name =="UnitName") {
        unit = el.value ? el.value.trim() : el.value;
      }
    });
    pendingShift.key= position+'_'+shift+'_'+unit;
    pendingShift.value =1;
    let isExist:boolean = false;
    this.pendingShiftValue.forEach(element => {
      if(element.key == pendingShift.key) {
        element.value +=1;
        isExist = true;
      }
      
    });
    if(!isExist){
      this.pendingShiftValue.push(pendingShift);
    }
  }

  getPartnerAgenciesPositions(selectedOrgLevel: OrgLevel) {
    this.isLoading = true;
    this.agenciesApiService.getAgencies(selectedOrgLevel.id).then((mappings: AgencyMapping[]) => {
      this.agencyMap = mappings.reduce(function (map, obj) {
        map[obj.agency.id] = obj.agency.name;
        return map;
      }, {});
      this.agencyDetails(selectedOrgLevel).then((res) => {
        this.IsEnabled = false;
        this.agencyListData = [];
        if (res == null || res.length == 0) {
          return;
        }
        // filter and for active agencies, then call async calls positionmap for
        let filteredAgencies = res.filter((o: DetailsPartner) => o.status == 'active');
        if (filteredAgencies == null || filteredAgencies.length == 0) {
          return;
        }
        this.getPositions(filteredAgencies);
        this.IsEnabled = true;
        this.requestShiftIsEnabled = true;
        let currentDate = moment().format(appConfig.dateFormat);
        let seletedDate = moment(this.dateOn).format(appConfig.dateFormat);
        if (!moment(currentDate).isAfter(seletedDate)) {
          this.requestShiftIsEnabled = false;
        }

        this.cancelTypeListData = [];
        res.forEach(e => {
          if (e.cancelType != null) {
            let cancelTypeObj = new CancelTypeList();
            cancelTypeObj.partnerId = e.partner_id;
            cancelTypeObj.types = e.cancelType;
            this.cancelTypeListData.push(cancelTypeObj);
          }
        });
        this.isLoading = false;
      });
    });
  }

  getPositions(filteredAgencies: DetailsPartner[]) {
    this.agencyListData = [];
    filteredAgencies.forEach((element) => {
      this.agencyData = element;
      this.agencyData.agency_name = this.agencyMap[this.agencyData.agency_id];
      this.agencyListData.push(this.agencyData);
      let checkPositions = this.shiftRequestService.getPartnerPositionMapData().get(this.agencyData.partner_id);
      if (checkPositions == null) {
        this.getPartnerPositions(this.alias, this.agencyData.partner_id);
      }
    });
    this.shiftRequestService.setAgencies(this.agencyListData);
  }

  getPartnerPositions(customer: string, partnerId: string) {
    this.management.getAgencyPostions(customer, partnerId).then((response: any) => {
      this.partnerPositions = response;
      this.shiftRequestService.setPartnerPositionMapData(partnerId, this.partnerPositions);


    });
  }




  public ngOnDestroy(): void {


    // #issueWithAOTCompiler
  }

  public setViewMode(routeMode: string): void {
    if (routeMode && routeMode in DetailGroupViewSettingsTypes) {
      this.currentViewMode = <DetailGroupViewSettingsTypes>routeMode;
    } else {
      this.restoreViewMode();
    }
  }

  public loadFilterLookups(selectedOrgLevelId: number): void {
    let positionGroupsPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.positionGroups,
      orgLevelId: selectedOrgLevelId,
    });
    let positionsPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.position,
      orgLevelId: selectedOrgLevelId,
    });
    let unitsPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.locationUnit,
      orgLevelId: selectedOrgLevelId,
    });
    let shiftsPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.shift,
      orgLevelId: selectedOrgLevelId,
    });

    Promise.all([positionGroupsPromise, positionsPromise, unitsPromise, shiftsPromise]).then((values: any[]) => {
      let duaOptions = new DailyUnitFilterOptions();
      [this.positionGroupLookup, this.positionLookup, this.unitsLookup, this.shiftsLookup] = values;
      duaOptions.positionGroupLookup = this.positionGroupLookup;
      duaOptions.positionLookup = this.positionLookup;
      duaOptions.unitsLookup = this.unitsLookup;
      duaOptions.shiftsLookup = this.shiftsLookup;
      this.management.duaOptions = duaOptions;
      this.restoreFilters();
    });
  }

  public get dateFormat(): string {
    return screenUtils.isMobile ? 'MM/dd/yy' : 'MMMM dd, yyyy';
  }

  public loadDetails(orgLevel: OrgLevel, dateOn: Date): Promise<Details> {
    return this.detailScreenService.getDailyUnits(orgLevel, dateOn);
  }

  public shiftOpen(row: DailyUnitGridEmployee): void {
    if (row.EmpId !== "" && row.EmpId === "0") {
      return;
    }
    this.navigateService.navigateToMasterScheduleOpenShifts(this.dateOn, row.EmpId);
  }

  public async onFillShift(row: DailyUnitGridEmployee): Promise<void> {
    let shiftIdField: string = row.ShiftId;
    let shiftNameField: string = row.ShiftName;
    let positionIdField: string = row.JobCode;
    let positionNameField: string = row.JobDescription;
    let unitIdField: string = row.UnitId;
    let unitNameField: string = row.UnitName;
    if (!shiftIdField || !positionIdField || !unitIdField) {
      return;
    }

    let orgLevel: OrgLevel;
    if (this.orgLevel.parentId > 0) {
      orgLevel = await this.orgLevelService.getOrgLevelByIdSafe(this.orgLevel.parentId);
    } else {
      orgLevel = this.orgLevel;
    }

    let request: ShiftReplacementRequest = new ShiftReplacementRequest();
    request.shiftId = +shiftIdField;
    request.shiftName = shiftNameField;
    request.positionId = +positionIdField;
    request.positionName = positionNameField;
    request.organizationName = orgLevel.name;
    request.unitId = +unitIdField;
    request.unitName = unitNameField;
    request.date = this.dateOn;
    request.showDayOffEmployees = true;
    request.showSendSmsButton = true;
      request.shiftStart = new Date(new Date().toISOString().slice(0,10) + " " + row.ShiftStart);
    request.shiftEnd = new Date(new Date().toISOString().slice(0,10) + " " + row.ShiftEnd);
    request.momentShiftStart = moment(row.ShiftStart);
    request.momentShiftEnd = moment(row.ShiftEnd);
    request.momentActualShiftStart = moment(row.actualShiftStart);
    request.momentActualShiftEnd = moment(row.actualShiftEnd);
    const GroupData : GroupDetailsData = {
      request : request,
      groups : this.previousGroups,
      previousDayGroups: this.previousDayDetails,
      nextDayGroups: this.nextDayDetails
    }
     EmployeeAddShiftOpenComponent.openDialog(
      GroupData,
      +unitIdField,
      this.modalService,
      (result: boolean, cmd: ShiftAddEmployeeCmd) => {
        if (result && cmd) {
          this.addShiftEmployee = cmd;
          this.state.isLoading = true;
          this.openDialogForPayPeriod(row,cmd.selectedEmployee.employee.id, 'add');
        }
      }
    );
  }

  public onOpenIndividual(row: DailyUnitGridEmployee): void {
    let employeeId = row.EmpId;
    let departamentId = row.DepartmentId;
    let posOrgLevel: OrgLevel = this.orgLevelService.getOrgLevelByRelatedItemId(
      +departamentId,
      OrgLevelType.department
    );
    if (!posOrgLevel) return;
    let orgLevelId: number = posOrgLevel.id;

    this.state.isLoading = true;
    this.scheduleCycleHelperService
      .getScheduleCycleByDate(moment(this.dateOn), orgLevelId)
      .then(({ startDate, endDate }: ScheduleCycle) => {
        this.state.isLoading = false;
        this.individualNavService.NavigateToIndividualScheduleEmp(
          +employeeId,
          startDate.toDate(),
          endDate.toDate(),
          this.dateOn
        );
      });
  }

  public onReplaceEmployee(row: DailyUnitGridEmployee): void {
    let employeeName = row.EmpName;
    let employeeId = row.EmpId;
    let shiftIdField = row.ShiftId;
    let shiftStartField = row.ShiftStart;
    let shiftEndField = row.ShiftEnd;
    let shiftNameField = row.ShiftName;
    let positionIdField = row.JobCode;
    let unitIdField = row.UnitId;
    if (!shiftIdField || !positionIdField || !unitIdField) {
      return;
    }
    if(this.isModifyPayPeriodApproved){
      if(!this.details.canEditScheduledApprovePayPeriod && this.details.canEditScheduledApprovePayPeriod != undefined && this.details) {
        this.openDialogForPayPeriod(row, employeeId, 'replace');
        return
      }
      this.shiftReplacementService.replaceEmployee.dateOn = this.dateOn;
      this.shiftReplacementService.replaceEmployee.shiftId  = parseInt(row.ShiftId);
       this.shiftReplacementService.isModifyApprovedPayperiod$.next(this.isModifyPayPeriodApproved);
       this.checkReplaceEmployeeApprovedStatus(row, row.EmpId);
    }
    let request: ShiftReplacementRequest = new ShiftReplacementRequest();
    request.replacedEmployeeName = employeeName;
    request.replacedEmployeeId = +employeeId;
    request.shiftId = +shiftIdField;
    request.unitId = +unitIdField;
    request.shiftName = shiftNameField;
    request.positionId = +positionIdField;
    request.date = this.dateOn;
    request.shiftStart = moment(shiftStartField).toDate();
    request.shiftEnd = moment(shiftEndField).toDate();
    request.showDayOffEmployees = true;
    request.orgLevelId = this.currentOrgLevel.id;
    request.momentShiftStart = moment(row.ShiftStart);
    request.momentShiftEnd = moment(row.ShiftEnd);
    request.momentActualShiftStart = moment(row.actualShiftStart);
    request.momentActualShiftEnd = moment(row.actualShiftEnd);
    request.currentDayDUAData = this.previousGroups;
    request.previousDayDUAData = this.previousDayDetails;
    request.nextDayDUAData = this.nextDayDetails;
    ShiftReplacementReplaceComponent.openDialog(
      request,
      this.modalService,
      (result: boolean, cmd: ShiftReplaceCmd): void => {
      if (result && cmd) {
        let isApprovedLength = this.shiftReplacementService.replaceApprovedResult.filter(x=> x ==true);
        if(this.isModifyPayPeriodApproved && isApprovedLength.length >0){
        this.shiftReplacementService.replaceEmployee = cmd;
        this.checkReplaceEmployeeApprovedStatus(row, this.shiftReplacementService.replaceEmployee.replacedEmployeeId);
        }
         else { 
          this.shiftReplacementService.replaceApprovedResult =[];
          this.state.isLoading = true;
          this.shiftReplacementApiService
            .replaceEmployee(cmd)
            .catch((error: any) => {
              this.state.isLoading = false;
            })
            .then((status: number) => {
              this.state.isLoading = false;
              this.loadDetailsEvent.next();
            });
          }
        }
      }
    );
  }

  public hideTable(groupTable: HTMLElement): void {
    $(groupTable).toggle();
  }

  public changeDateOn(dateOn: Date, flag: boolean): void {
    this.isLoading = true;
    if (flag) {
      this.clearFilters();
    }
    this.navigateService.navigateToDailyUnitAssignmentDate(
      dateOn,
      this.pathPositionIds,
      this.currentViewMode,
      false,
      this.unitIds,
      this.shiftIds
    );
    this.getPositions(this.agencyListData);
    this.isLoading = false;

  }
  
  public onFilterChange(filterName: string, filter: any[]): void {
    this.state.isSelectPropagated = false;

    let ids: number[] = _.map(filter, (m) => m.id);
    if (filterName === 'unit') {
      this.selectedUnitIds = ids;
    } else if (filterName === LookupType.position) {
      this.selectedPositionIds = ids;
    } else if (filterName === LookupType.shift) {
      this.selectedShiftIds = ids;
    } else if (filterName === LookupType.positionGroups) {
      this.selectedPositionGroupIds = ids;
    }

    if (filter) {
      this.filtersChanged = filter;
    }
  }

  public calcAppliedFilters(): void {
    this.filtersApplied =
      Boolean(this.selectedUnitIds && this.selectedUnitIds.length) ||
      Boolean(this.selectedPositionIds && this.selectedPositionIds.length) ||
      Boolean(this.selectedShiftIds && this.selectedShiftIds.length) ||
      Boolean(this.selectedPositionGroupIds && this.selectedPositionGroupIds.length);
  }

  public filterRows(rows: DetailRow[]): DetailRow[] {    
    let partialShifts :DetailRow[];
    let filtered: DetailRow[] = this.filterRowsBy(rows, 'UnitId', this.selectedUnitIds);
    filtered = this.filterRowsBy(filtered, 'ShiftId', this.selectedShiftIds);
    this.selectedShiftIds.length>0 ? partialShifts = this.filterRowsBy(rows, 'ParentShiftId', this.selectedShiftIds) : partialShifts = [];
    filtered = this.filterRowsBy(filtered, 'JobCode', this.selectedPositionIds);
    filtered = this.filterRowsBy(filtered, 'PositionGroupId', this.selectedPositionGroupIds);
    return filtered.concat(partialShifts);
  }

  public filterRowsBy(rows: DetailRow[], fieldName: string, filteredItemIds: number[]): DetailRow[] {
    if (!filteredItemIds || filteredItemIds.length === 0) return rows;

    let filtered: DetailRow[] = _.filter(rows, (row: DetailRow) => {
      let field: DetailField = _.find(row.fields, (f: DetailField) => f.name === fieldName);
      let id: number = _.find(filteredItemIds, (itemId: number) => +itemId === +field.value);
      return !!id;
    });

    return filtered;
  }

  public stopPropagation($event: Event): void {
    if (this.state.isSelectPropagated) {
      $event.stopPropagation();
    } else {
      this.state.isSelectPropagated = true;
    }
  }

  public employeeDelete(row: DailyUnitGridEmployee): void {
    let shiftIdField = row.ShiftId;
    let employeeId = row.EmpId;
    this.openDialogForPayPeriod(row, employeeId, 'delete');
    }

  public employeeCancel(details: any): void {
    this.isLoading = true;
    let shiftIdField = details.row.ShiftId;
    let employeeId = details.row.EmpId;
    let requestId = details.row.SlxpartnerdataId;
    let partnerId = details.row.PartnerId;
    let cancelType = details.option;
    this.detailScreenService
      .cancelDailyUnitEmployee(this.dateOn, +shiftIdField, +employeeId, partnerId, +requestId, cancelType).then(() => {
        this.isLoading = false;
        this.loadDetailsEvent.next();
      });

  }

  public onSwitchEmployeeShift(row: DailyUnitGridEmployee): void {
    this.rowForShiftChange = row;
    this.openDialogForPayPeriod(row, row.EmpId, 'switch')
  }

  public discardShiftSwitch(): void {
    this.rowForShiftChange = null;
    this.state.switchShiftMode = false;
  }

  public performShiftSwitch(item: OpenShiftDetails): void {
    let field: DetailField;
    let empId: number;
    empId = parseInt(this.rowForShiftChange.EmpId);
    this.openDialogForPayPeriod(item,empId,'switchShift');    
  }

  public get censusCount(): number {
    return this.details ? this.details.censusCount : 0;
  }

  public onCensusEdit(): void {
    this.navigateService.navigateToCensusEditor(this.dateOn);
  }

  public savePreviousFilters(): void {
    this.prePositionGroupFilter = this.positionGroupFilter ? this.positionGroupFilter.concat([]) : [];
    this.prevPosFilter = this.posFilter ? this.posFilter.concat([]) : [];
    this.prevUnitFilter = this.unitFilter ? this.unitFilter.concat([]) : [];
    this.prevShiftFilter = this.shiftFilter ? this.shiftFilter.concat([]) : [];
  }

  public applyFilters(): void {
    this.state.isLoading = true;
    if (this.details) {
      this.previousGroups = _.cloneDeep(this.details.groups);
    }
    _.each(this.previousGroups, (group: DetailGroup) => {
      group.rows = this.filterRows(group.rows);
      group.recalculateFilteredValues();
    });
    this.calcAppliedFilters();
    this.state.isLoading = false;
  }

  public clickEventForOpenTile(toggleFlag: boolean): void {
    if (this.openTotalShifts > 0) {
      this.openShiftToggle = (toggleFlag === false);
      _.each(this.previousGroups, (group: DetailGroup) => {
         group.isEmptySlotFilterSelected = this.openShiftToggle;
         group.recalculateFilteredValues();
      });
    }
  }

  public clickEventForPendingTile(toggleFlag: boolean): void {
    if (this.agencyTotalShifts > 0) {
      this.pendingShiftToggle = (toggleFlag === false);
      _.each(this.previousGroups, (group: DetailGroup) => {
        group.isPendingSlotFilterSelected = this.pendingShiftToggle;
        group.recalculateFilteredValues();
      });
    }
  }

  public clickEventForSwitchTile(toggleFlag: boolean): void {
    if (this.switchTotalShift > 0) {
      this.switchShiftToggle = (toggleFlag === false);
      _.each(this.previousGroups, (group: DetailGroup) => {
        group.isOutOfParFilterSelected = this.switchShiftToggle;
        group.recalculateFilteredValues();
      });
    }
  }

  public clickEventForOverTimeTile(toggleFlag: boolean): void {
    if (this.overTimeTotalShifts > 0) {
      this.overTimeShiftToggle = (toggleFlag === false);
      _.each(this.previousGroups, (group: DetailGroup) => {
        group.isOvertimeFilterSelected = this.overTimeShiftToggle;
        group.recalculateFilteredValues();
      });
    }
  }

  public cancelFilters(): void {
    this.positionGroupFilter = this.prePositionGroupFilter;
    this.posFilter = this.prevPosFilter;
    this.unitFilter = this.prevUnitFilter;
    this.shiftFilter = this.prevShiftFilter;
  }

  public clearFilters(): void {
    this.isActionsOpened = false;
    this.buttonNum = 0;
    this.pathPositionGroupIds = [];
    this.pathPositionIds = [];
    this.unitIds = [];
    this.shiftIds = [];
    this.positionGroupFilter = [];
    this.posFilter = [];
    this.unitFilter = [];
    this.shiftFilter = [];
    this.prePositionGroupFilter = [];
    this.prevPosFilter = [];
    this.prevUnitFilter = [];
    this.prevShiftFilter = [];
    this.selectedPositionGroupIds = [];
    this.selectedPositionIds = [];
    this.selectedShiftIds = [];
    this.selectedUnitIds = [];
    this.saveFilters();
    this.savePreviousFilters();
    this.applyFilters();
    this.restoreGroupsStates(this.previousGroups);
    this.restoreFilters();
    this.openShiftToggle = false;
    this.pendingShiftToggle = false;
    this.switchShiftToggle = false;
    this.overTimeShiftToggle = false;
  }

  public viewSettingsChanged(event: DetailGroupViewSettingsTypes): void {
    this.currentViewMode = event;
    this.saveViewMode();
    this.categoriesDetails();
  }

  public saveFilters(): void {
    let filters: any = {
      positionGroup: this.positionGroupFilter,
      positions: this.posFilter,
      units: this.unitFilter,
      shifts: this.shiftFilter,
    };
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      FILTERS,
      { value: filters },
      StateResetTypes.SessionEnd |
      StateResetTypes.OrgLevelChange |
      StateResetTypes.MenuChange |
      StateResetTypes.ParameterInRoute
    );

    if (this.selectedPositionGroupIds && this.selectedPositionGroupIds.length) {
      this.pathPositionGroupIds = this.selectedPositionGroupIds.concat([]);
    } else {
      this.pathPositionGroupIds = [];
    }

    if (this.selectedPositionIds && this.selectedPositionIds.length) {
      this.pathPositionIds = this.selectedPositionIds.concat([]);
    } else {
      this.pathPositionIds = [];
    }

    if (this.selectedUnitIds && this.selectedUnitIds.length) {
      this.unitIds = this.selectedUnitIds.concat([]);
    } else {
      this.unitIds = [];
    }

    if (this.selectedShiftIds && this.selectedShiftIds.length) {
      this.shiftIds = this.selectedShiftIds.concat([]);
    } else {
      this.shiftIds = [];
    }

    this.changeDateOn(this.dateOn, false);
  }

  public groupContainerCreated(item: HTMLElement): void {
    this.groupContainer = item;
    this.scrollGroupContainer();
  }

  public scrollTo(value: number): void {
    this.scrollToValue = value;
    this.scrollGroupContainer();
  }

  public get totalEmptySlotCount(): number {
    if (!this.previousGroups) return 0;
    let mapped: number[] = _.map(this.previousGroups, 'emptySlotCount');
    return _.sum(mapped);
  }

  private scrollGroupContainer(): void {
    if (this.groupContainer && this.scrollToValue > 0) {
      this.groupContainer.scrollTop = this.scrollToValue - this.groupContainer.offsetTop;
      this.scrollToValue = 0;
      this.groupContainer = null;
    }
  }

  private saveGroupState(group: DetailGroup, prop: string, propValue: boolean): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, group.name);
    if (!state || !state.value) {
      state = { value: {} };
    }
    state.value[prop] = propValue;
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      group.name,
      state,
      StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange
    );
  }

  private saveViewMode(): void {
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      VIEW_MODE_KEY,
      { value: this.currentViewMode },
      StateResetTypes.None
    );
  }

  private saveDate(date: Date): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, DATE);
    if (!state || !state.value) {
      state = { value: {} };
    }
    state.value = date;
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      DATE,
      state,
      StateResetTypes.SessionEnd | StateResetTypes.MenuChange
    );
  }

  public getSavedDate(): Date {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, DATE);
    if (state.value) return state.value;
    return new Date();
  }

  private restoreViewMode(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, VIEW_MODE_KEY);
    if (state.value && state.value in DetailGroupViewSettingsTypes) {
      this.currentViewMode = state.value;
    } else {
      this.currentViewMode = DetailGroupViewSettingsTypes.hours;
    }
  }

  private restoreFilters(): void {
    let positionGroupToSelect: PositionGroup[] = [];
    if (this.pathPositionGroupIds && this.pathPositionGroupIds.length) {
      if (this.positionGroupLookup && this.positionGroupLookup.items) {
        _.forEach(this.pathPositionGroupIds, (id: number) => {
          let pos: PositionGroup = _.find(this.positionGroupLookup.items, (item: PositionGroup): boolean => {
            return item.id === id;
          });
          if (pos) positionGroupToSelect.push(pos);
        });
        if (positionGroupToSelect && positionGroupToSelect.length) {
          this.positionGroupFilter = positionGroupToSelect;
          this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
        }
      }
    }

    let positionToSelect: Position[] = [];

    if (this.pathPositionIds && this.pathPositionIds.length) {
      if (this.positionLookup && this.positionLookup.items) {
        _.forEach(this.pathPositionIds, (id: number) => {
          let pos: Position = _.find(this.positionLookup.items, (item: Position): boolean => {
            return item.id === id;
          });
          if (pos) positionToSelect.push(pos);
        });
        if (positionToSelect && positionToSelect.length) {
          this.posFilter = positionToSelect;
          this.onFilterChange(LookupType.position, this.posFilter);
        }
      }
    }

    let unitToSelect: LocationUnit[] = [];
    if (this.unitIds && this.unitIds.length) {
      if (this.unitsLookup && this.unitsLookup.items) {
        _.forEach(this.unitIds, (id: number) => {
          let unit: LocationUnit = _.find(this.unitsLookup.items, (item: LocationUnit): boolean => {
            return item.id === id;
          });
          if (unit) unitToSelect.push(unit);
        });
        if (unitToSelect && unitToSelect.length) {
          this.unitFilter = unitToSelect;
          this.onFilterChange('unit', this.unitFilter);
        }
      }
    }

    let shiftsToSelect: Shift[] = [];
    if (this.shiftIds && this.shiftIds.length) {
      if (this.shiftsLookup && this.shiftsLookup.items) {
        _.forEach(this.shiftIds, (id: number) => {
          let shift: Shift = _.find(this.shiftsLookup.items, (item: Shift): boolean => {
            return item.id === id;
          });
          if (shift) shiftsToSelect.push(shift);
        });
        if (shiftsToSelect && shiftsToSelect.length) {
          this.shiftFilter = shiftsToSelect;
          this.onFilterChange(LookupType.shift, this.shiftFilter);
        }
      }
    }

    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, FILTERS);
    let filters = state.value;
    if (filters) {
      if (!positionGroupToSelect || !positionGroupToSelect.length) {
        this.positionGroupFilter = filters.positionGroup;
      }
      if (!positionToSelect || !positionToSelect.length) {
        this.posFilter = filters.positions;
      }
      if (!unitToSelect || !unitToSelect.length) {
        this.unitFilter = filters.units;
      }
      if (!shiftsToSelect || !shiftsToSelect.length) {
        this.shiftFilter = filters.shifts;
      }
      this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
      this.onFilterChange(LookupType.position, this.posFilter);
      this.onFilterChange(LookupType.shift, this.shiftFilter);
      this.onFilterChange('unit', this.unitFilter);
    } else {
      if (!positionGroupToSelect || !positionGroupToSelect.length) {
        this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
      }
      if (positionToSelect && positionToSelect.length) {
        this.onFilterChange(LookupType.position, this.posFilter);
      }
      if (unitToSelect || unitToSelect.length) {
        this.onFilterChange('unit', this.unitFilter);
      }
      if (shiftsToSelect || shiftsToSelect.length) {
        this.onFilterChange(LookupType.shift, this.shiftFilter);
      }
    }
    this.applyFilters();
  }

  private restoreGroupsStates(groups: DetailGroup[]): void {
    _.forEach(groups, (group: DetailGroup) => {
      let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, group.name);
      if (state && state.value) {
        let val: any = state.value;
        group.isEmptySlotFilterSelected = val.isEmptySlotFilterSelected && group.emptySlotCount > 0;
        group.isOutOfParFilterSelected = val.isOutOfParFilterSelected && group.outOfParCount > 0;
        group.isMessageFilterSelected = val.isMessageFilterSelected && group.messageCount > 0;
        group.isOvertimeFilterSelected = val.isOvertimeFilterSelected && group.overtimeCount > 0;
        group.isPendingSlotFilterSelected = val.isPendingSlotFilterSelected && group.pendingShiftCount > 0;
        group.recalculateFilteredValues();
      }
    });
  }
  public AddOpenShifts() :void { 
    if(this.isModifyPayPeriodApproved){
        this.approvedPayperiodForOrgLevel();
    }
    else this.router.navigateByUrl(this.getOpenShiftUrlTree());
  }
  public getOpenShiftUrlTree():UrlTree {
    let params: any = {
      keepOrgLevelBreadcrmb: true,
      isAgencyEnabled: this.IsEnabled,
      dateOn: moment(this.dateOn).format(appConfig.linkDateFormat),
    };
    return this.router.createUrlTree(
      ['daily_unit_assignment', 'add_open_shifts'],
      {
        relativeTo: this.activatedRoute.pathFromRoot[2],
        queryParams: params
      });
  }
  public async requestAgencyStaff(): Promise<void> {
    this.isLoading = true;
    this.shiftRequestService.isRequestFromDua = true;
    let agencyName: string;
    this.requestShift = true;
    let requestdata: any = [];
    let OpenShiftData: any = [];
    this.previousGroups.forEach((el) => {
      requestdata.push(el.rows);
    });
    if (requestdata.length > 0) {
      requestdata.forEach((e) => {
        e.forEach((child) => {
          OpenShiftData.push(child);
        });
      });
    }

    this.shiftRequestService.setGridData(OpenShiftData);
    let requestAgencygridData = this.shiftRequestService.filterOpenShiftsData(this.shiftRequestService.getGridData());
    let partnerPositionMapData = this.shiftRequestService.getPartnerPositionMapData();
    // The positions are defined manually. Verify all of them mapped.

    let currentAgency: DetailsPartner;
    for (let agency of this.agencyListData) {

      let currentPositionsData: DetailsAgencyPosition[] = partnerPositionMapData.get(agency.partner_id).positions;
      this.isPositionMapped = true;
      // Partner position each agency 
      this.positionTabs = [];
      await this.getPartnerPositionTab(agency.partner_id)
      if (currentPositionsData.length > 0) {
        let checkMinimalPositionMapped = false;
        for (let position of this.positionTabs) {

          let currentPosition = currentPositionsData.find(e => e.partner_position_name == position && e.position_name != null && e.position_name != '');
          if (currentPosition != null) {
            checkMinimalPositionMapped = true;
            break;
          }
        }
        if (!checkMinimalPositionMapped) {
          this.isPositionMapped = false;
          break;
        }
      } else {
        this.isPositionMapped = false;
      }
      if (!this.isPositionMapped) {
        currentAgency = agency;
        break;
      }
    };

    if (this.isPositionMapped) {

      let orgLevel: OrgLevel;
      orgLevel = this.orgLevel;
      this.shiftRequestService.setGridData(OpenShiftData);
      this.NavigateToRequestAgencyStaff();
    }
    else {
      this.positionNotMappedDialogue(currentAgency.agency_name);
    }
    this.isLoading =false;
  }
  public async getPartnerPositionTab(partnerId) {
    this.positionTabs = [];
    let positionDefination: any[] = [];
    await this.partnerConfigManagementService.getPartnerPositionsDefinitions(partnerId).then((response: any) => {
      this.isLoading = true;
      response.forEach(element => {
        positionDefination.push(element.partnerPositionName);
      });
      this.positionTabs = positionDefination;
    });
  }
  public positionNotMappedDialogue(agencyName: string): void {
    let options: ConfirmOptions3 = new ConfirmOptions3();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = "Go to Position Mapping";
    options.buttonCanceltext = 'Cancel';
    options.note = `There are missing Position Mappings against (${agencyName}) that must be fixed before you can proceed.`;
    ConfirmDialog3Component.openDialog(
      'Position Mapping ', '',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.isLoading = true;
          this.shiftRequestService.positionsClickEvent();
          this.isLoading = false;
        }
      }, options);
      this.isLoading = false;

  }
  public NavigateToRequestAgencyStaff(): void {
    this.router.navigateByUrl(this.getUrlTree());
  }

  public getUrlTree(): UrlTree {
    let params: any = {
      keepOrgLevelBreadcrmb: true,
      dateOn: moment(this.dateOn).format(appConfig.linkDateFormat)
    };
    return this.router.createUrlTree(
      ['daily_unit_assignment', 'request_agency_staff', moment(this.dateOn).format(appConfig.linkDateFormat)],
      {
        relativeTo: this.activatedRoute.pathFromRoot[2],
        queryParams: params
      });
  }

  agencyDetails(orgLevel: OrgLevel): Promise<any> {
    return this.detailScreenService.getAgencyDetails(orgLevel, this.alias);
  }

  categoriesDetails() {
    this.schedTotal = 0;
    this.schedIdealTotal = 0;
    this.dayTotal = 0;
    this.dayIdealTotal = 0;
    this.eveningTotal = 0;
    this.eveningIdealTotal = 0;
    this.nightTotal = 0;
    this.nightIdealTotal = 0;
    this.agencyTotalShifts = 0;
    this.agencyNightShifts = 0;
    this.agencyEveningShifts = 0;
    this.agencyDayShifts = 0;
    this.openTotalShifts = 0;
    this.openDayShifts = 0;
    this.openEveningShifts = 0;
    this.openNightShifts = 0;
    this.overTimeTotalShifts = 0;
    this.overTimeDayShifts = 0;
    this.overTimeEveningShifts = 0;
    this.switchTotalShift = 0;
    this.switchDayShift = 0;
    this.switchEveningShift = 0;
    this.switchNightShift = 0;

    this.details && this.details.groups && this.details.groups.forEach((groupsData) => {
      groupsData.recalculateFilteredValues();
      if (groupsData.shiftGroupName === 'Day') {
        if (this.currentViewMode === 'hours') {
          this.dayTotal += groupsData.filteredScheduledHours;
          this.dayIdealTotal += groupsData.filteredTotalIdealHours;
        } else if (this.currentViewMode === 'slots') {
          this.dayTotal += groupsData.filteredFilledSlots;
          this.dayIdealTotal += groupsData.filteredTotalSlots;
        } else if (this.currentViewMode === 'ppd') {
          this.dayTotal += groupsData.filteredFilledPpd;
          this.dayIdealTotal += groupsData.filteredTotalPpd;
        }
        this.agencyDayShifts = groupsData.pendingShiftCount;
        this.openDayShifts = groupsData.emptySlotCount - groupsData.pendingShiftCount;
        this.overTimeDayShifts = groupsData.overtimeCount;
        this.switchDayShift = groupsData.filterSwitchShiftCount;
      }

      if (groupsData.shiftGroupName === 'Evening') {
        if (this.currentViewMode === 'hours') {
          this.eveningTotal += groupsData.filteredScheduledHours;
          this.eveningIdealTotal += groupsData.filteredTotalIdealHours;
        } else if (this.currentViewMode === 'slots') {
          this.eveningTotal += groupsData.filteredFilledSlots;
          this.eveningIdealTotal += groupsData.filteredTotalSlots;
        } else if (this.currentViewMode === 'ppd') {
          this.eveningTotal += groupsData.filteredFilledPpd;
          this.eveningIdealTotal += groupsData.filteredTotalPpd;
        }
        this.agencyEveningShifts = groupsData.pendingShiftCount;
        this.openEveningShifts = groupsData.emptySlotCount - groupsData.pendingShiftCount;
        this.overTimeEveningShifts = groupsData.overtimeCount;
        this.switchEveningShift = groupsData.filterSwitchShiftCount;
      }
      if (groupsData.shiftGroupName === 'Night') {
        if (this.currentViewMode === 'hours') {
          this.nightTotal += groupsData.filteredScheduledHours;
          this.nightIdealTotal += groupsData.filteredTotalIdealHours;
        } else if (this.currentViewMode === 'slots') {
          this.nightTotal += groupsData.filteredFilledSlots;
          this.nightIdealTotal += groupsData.filteredTotalSlots;
        } else if (this.currentViewMode === 'ppd') {
          this.nightTotal += groupsData.filteredFilledPpd;
          this.nightIdealTotal += groupsData.filteredTotalPpd;
        }
        this.agencyNightShifts = groupsData.pendingShiftCount;
        this.openNightShifts = groupsData.emptySlotCount - groupsData.pendingShiftCount;
        this.overTimeNightShifts = groupsData.overtimeCount;
        this.switchNightShift = groupsData.filterSwitchShiftCount;
      }
    });
    this.overTimeTotalShifts = this.overTimeDayShifts + this.overTimeEveningShifts + this.overTimeNightShifts;
    this.openTotalShifts = this.openDayShifts + this.openEveningShifts + this.openNightShifts;
    this.schedTotal = this.dayTotal + this.eveningTotal + this.nightTotal;
    this.schedIdealTotal = this.dayIdealTotal + this.eveningIdealTotal + this.nightIdealTotal;
    this.agencyTotalShifts = this.agencyDayShifts + this.agencyEveningShifts + this.agencyNightShifts;
    this.switchTotalShift = this.getTotalSwitchShiftCount;
  }

  private get getTotalSwitchShiftCount(): number {
    if(this.openTotalShifts > 0 && this.agencyTotalShifts > 0) {
      if((this.openTotalShifts - this.agencyTotalShifts) > 0) {
        return this.switchDayShift + this.switchEveningShift + this.switchNightShift;
      }
      else {
        return 0;
      }
    }
    else {
      return this.switchDayShift + this.switchEveningShift + this.switchNightShift;
    }
  }

  public isVisibleSection(sectionType: ToolbarSectionTypes): boolean {
    return sectionType === ToolbarSectionTypes.VISIBLE;
  }

  public isAllSectionShown(sections: StringMap<number>): boolean {
    return sections[ToolbarSectionTypes.COLLAPSED_BY_RESIZE] === 0;
  }

  public captureScreen() {
    let data = document.getElementById('contentToConvert');
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      let imgWidth = 210;
      let pageHeight = 295;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');

      let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let position = 10;
      let _selectedDateVal = document.getElementById('_selectedDateVal').innerText;
      let current = new Date(_selectedDateVal);
      let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
      let cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
      let dateTime = cDate + ' ' + cTime;

      pdf.setFontSize(10);
      pdf.text(dateTime, 7, 7);
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('Daily_Unit_Assignment_' + cDate + '.pdf'); // Generated PDF
    });
  }

   public addShift(addEmployeeCmdObj){
    this.shiftReplacementApiService
    .addToShift(addEmployeeCmdObj)
    .catch((error: any) => {
      this.state.isLoading = false;
    })
    .then((status: number) => {
      this.state.isLoading = false;
      this.loadDetailsEvent.next();
    });
   }

   public openDialogForPayPeriod(row, empId, actionType){
    let shiftName = actionType== 'switchShift' ? row.shift.name : row.ShiftName;
    let shiftEndDate = shiftName.split('-')[0].includes('p') && shiftName.split('-')[1].includes('a') ? moment(this.dateOn).add(1,'day') : this.dateOn;
    let startDate = this.setDateFormat(this.dateOn);
    let endDate =  this.setDateFormat(this.dateOn);
    let empid = empId;
    this.scheduleEntryApiService.checkApprovedPayperiod(empid,startDate,endDate).then((data: any) => {
      this.approvedPayPeriod = data;
      if(this.approvedPayPeriod && this.isModifyPayPeriodApproved){
        const message = this.details.canEditScheduledApprovePayPeriod ? `This will modify a schedule in an approved pay period and impact the PBJ Calculation for the employee, are you sure you want to continue?` : 'You do not have permissions to modify a schedule in an approved pay period';
        let popupOptions: ConfirmOptions = new ConfirmOptions();
        popupOptions.showCancel = true;
        popupOptions.showOK = this.details.canEditScheduledApprovePayPeriod ? true : false;;
        popupOptions.buttonOKtext = 'Ok';
        popupOptions.buttonCanceltext = this.details.canEditScheduledApprovePayPeriod ? 'Cancel' : 'Ok'
        ConfirmDialog2Component.openDialog(
          'Warning',
          message,
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.actionsBasedOnPayPeriod(row, empId, actionType);
            }
      },popupOptions);
       }
       else {
        this.actionsBasedOnPayPeriod(row, empId, actionType);
      }
    })
   }

   public actionsBasedOnPayPeriod(row, empId, actionType){
    switch(actionType){
      case 'add':
        this.addShift(this.addShiftEmployee);
        break;
      case 'delete':
        this.detailScreenService.deleteDailyUnitEmployee(this.dateOn, row.ShiftId, row.EmpId).then(() => this.loadDetailsEvent.next());
        break;
      case 'switch':
        this.state.switchShiftMode = true;
        break;
      case 'switchShift':
        {
          let originalId: number;
          let replaceId: number;
          let replaceUnitId: number;
          originalId = parseInt(this.rowForShiftChange.ShiftId);
          replaceId = parseInt(row.shift.id.toString());
          replaceUnitId = parseInt(row.unit.id.toString());
           
          this.state.isLoading = true;
          this.scheduleApiService.changeOpenShift(this.dateOn, empId, originalId, replaceId, replaceUnitId)
          .then(() => {
            this.state.isLoading = false;
            this.rowForShiftChange = null;
            this.state.switchShiftMode = false;
            this.loadDetailsEvent.next();
           })
          .catch((err: any) => {
            this.state.isLoading = false;
        });
        }
    }
   }

   private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.isModifyPayPeriodApproved =  config.ModifySchedulesApprovedinPayPeriods;
  }

  private  checkReplaceEmployeeApprovedStatus(row, empId){
    let shiftEndDate = row.ShiftName.split('-')[0].includes('p') && row.ShiftName.split('-')[1].includes('a') ? moment(this.dateOn).add(1,'day') : this.dateOn;
    let startDate =this.setDateFormat(this.dateOn);
    let endDate =  this.setDateFormat(this.dateOn);
    this.scheduleEntryApiService.checkApprovedPayperiod(empId,startDate,endDate).then((data: any) => {
      this.shiftReplacementService.replaceApprovedResult.push(data);
    })
  }

  public approvedPayperiodForOrgLevel(){
    this.startDate = this.setDateFormat(this.dateOn);
    this.scheduleEntryApiService.checkApprovedPayperiodForOrgLevel(this.orgLevel.id , this.startDate, this.startDate).then(data =>{
      this.isOrglevelPayPeriodApproved = data;
      if(this.isOrglevelPayPeriodApproved){
        const message = this.details.canEditScheduledApprovePayPeriod ? `This will modify a schedule in an approved pay period and impact the PBJ Calculation for the employee, are you sure you want to continue?` : 'You do not have permissions to modify a schedule in an approved pay period';
          let popupOptions: ConfirmOptions = new ConfirmOptions();
          popupOptions.showCancel = true;
          popupOptions.showOK = this.details.canEditScheduledApprovePayPeriod ? true : false;;
          popupOptions.buttonOKtext = 'Ok';
          popupOptions.buttonCanceltext = this.details.canEditScheduledApprovePayPeriod ? 'Cancel' : 'Ok'
          ConfirmDialog2Component.openDialog(
            'Warning',
            message,
            this.modalService,
            (result: boolean) => {
              if (result) {
                  this.router.navigateByUrl(this.getOpenShiftUrlTree());
              }
           },popupOptions)
        
      }
      else this.router.navigateByUrl(this.getOpenShiftUrlTree());
    })
  }

  public setDateFormat(date:Date){
    return moment(date).format(`MM-DD-YYYY`);
  }
  public isTablet(){
    return this.screenWidth < 1023 ? true : false
  }
  public isMobile(){
    return this.screenWidth<768 ? true : false
  }
  public getPopperContentClass(){
     if(this.screenWidth < 1023 && this.screenWidth >768) return 'customPopperContentClass'
     if(this.screenWidth < 768) return 'mobilePopperContentClass'
  }

  public openModal(){
    this.isActionsOpened = false;
    let options = new DailyUnitFilterOptions();
    this.management.duaOptions.positionGroupFilter = this.positionGroupFilter;
    this.management.duaOptions.posFilter = this.posFilter;
    this.management.duaOptions.unitFilter = this.unitFilter;
    this.management.duaOptions.shiftFilter = this.shiftFilter;
    const dialog: DailyUnitFilterDialogComponent = DailyUnitFilterDialogComponent.openDialog(options, this.modalService, (result: boolean): void => {
      if (result) {
          this.selectedUnitIds = this.management.duaOptions.selectedUnitIds;
          this.selectedPositionGroupIds = this.management.duaOptions.selectedPositionGroupIds;
          this.selectedShiftIds = this.management.duaOptions.selectedShiftIds;
          this.selectedPositionIds = this.management.duaOptions.selectedPositionIds;
          this.posFilter = this.management.duaOptions.posFilter;
          this.unitFilter = this.management.duaOptions.unitFilter;
          this.positionGroupFilter = this.management.duaOptions.positionGroupFilter;
          this.shiftFilter = this.management.duaOptions.shiftFilter;
          this.applyFilters();
          this.saveFilters();
          this.state.isLeftSidebarOpen = false;
        }
          
  })
  }

  public openViewSettingsModal(){
    this.isActionsOpened = false;
    this.management.duaOptions.settingsType = this.currentViewMode;
    let options = new DailyUnitFilterOptions();
    const dialog: DailyUnitViewSettingsPopupComponent = DailyUnitViewSettingsPopupComponent.openDialog(options, this.modalService, (result: boolean): void => {
      if (result) {
        this.currentViewMode = this.management.duaOptions.settingsType;
        this.saveViewMode();
        this.categoriesDetails();
      }
    })
    }
}
