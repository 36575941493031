import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router, UrlTree } from '@angular/router';
import { appConfig, IApplicationConfig } from '../../../../app/app.config';
import { ComponentStateStorageService, KendoGridStateHelper, ModalService, StateManagementService } from '../../../../app/common';
import { DailyOpenShiftDetailsData, Details, DetailsAgencyModel, DetailsPartner } from '../../models';
import { GroupDescriptor, process, State } from '@progress/kendo-data-query';
import { ShiftRequestService } from '../../services/schedule/shift-request.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DailyUnitGridComponent } from '../daily-unit-grid/daily-unit-grid.component';
import { DetailScreenService, ScheduleApiService, ScheduleEntryApiService, ScheduleEntryManagementService, ShiftReplacementApiService, ShiftReplacementManagementService } from '../../services';
import { LookupService, OrgLevelWatchService, ScheduleCycleHelperService } from '../../../../app/organization';
import { AgenciesApiService, PartnerConfigManagementService } from '../../../../app/configuration/services';
import { AppSettingsManageService } from '../../../../app/app-settings/services';
import { OrgLevel } from '../../../../app/state-model/models';
import { Observable } from 'rxjs';
import { mutableSelect } from '../../../../app/core/decorators';
import { ISession } from '../../../../app/authentication/store';
import { SessionService } from '../../../../app/core/services';
import { forEach } from 'lodash';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
@Component({
  selector: 'slx-request-agency-staff',
  templateUrl: './request-agency-staff.component.html',
  styleUrls: ['./request-agency-staff.component.scss']
})
export class RequestAgencyStaffComponent extends DailyUnitGridComponent implements OnInit {

  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  public appConfig: IApplicationConfig;
  public pageSize: number = 10;
  public orgLevelId: number;
  public requestedDate: Date;
  public gridState: KendoGridStateHelper<any>;
  public isShiftSelected: boolean = false;
  public displayId: number = 0;
  public selectedShiftDetails: any[] = [];
  public updatedShiftDetails: any[] = [];
  public editShiftDetails: any[] = [];
  public isRequestAgencyStaff: boolean = false;
  public selectedAgencyCount: string;
  public displayHeader: string;
  public userName: string;
  public alias: string;
  public login: string;
  public userId: number;
  public group: GroupDescriptor[] = [{ field: 'Hours' }];
  public agency: DetailsAgencyModel[] = [];
  public currentTime: moment.Moment;
  public isRequestAgency: boolean = true;
  public storeSelectedData: DailyOpenShiftDetailsData;
  public storeSelectedDataArr: any = [];
  public currentOffset: number;
  public scrollCountentSize: number = 3;
  public multiSelectShift:any [] =[];
  public states: State = {};
  public isEditMode: boolean;
  public isMultiSelectedShift: boolean = false;
  public agencyModelData: DetailsAgencyModel[];
  constructor(private route: ActivatedRoute,
    private sessionService: SessionService,
    private shiftService: ShiftRequestService,
    scheduleApiService: ScheduleApiService,
    detailScreenService: DetailScreenService,
    modalService: ModalService,
    router: Router,
    shiftReplacementApiService: ShiftReplacementApiService,
    scheduleCycleHelperService: ScheduleCycleHelperService,
    lookupService: LookupService,
    stateManagement: StateManagementService,
    storageService: ComponentStateStorageService,
    orgLevelService: OrgLevelWatchService,
    shiftRequestService: ShiftRequestService,
    management: ScheduleEntryManagementService,
    agenciesApiService: AgenciesApiService,
    appSettingsManageService: AppSettingsManageService,
    partnerConfigManagementService: PartnerConfigManagementService,
    scheduleEntryApiService: ScheduleEntryApiService, ShiftReplacementManagementService : ShiftReplacementManagementService) {
    super( scheduleEntryApiService,scheduleApiService, detailScreenService, modalService, route, router, ShiftReplacementManagementService, shiftReplacementApiService,
      scheduleCycleHelperService, lookupService, stateManagement, storageService, orgLevelService,
      shiftRequestService, management,
      agenciesApiService,
      appSettingsManageService,partnerConfigManagementService);
    this.gridState = new KendoGridStateHelper<any>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
  }

  async ngOnInit() {
    this.appConfig = appConfig;

    
    if (!this.dateOn) {
      this.dateOn = this.getSavedDate();
    }
    await super.ngOnInit();
  
    if (!this.shiftService.isRequestFromDua) {
     
      this.onLoad = true;  
      this.orgLevelSubscripion = this.orgLevel$.subscribe((selectedOrgLevel: OrgLevel) => {
        this.orgLevel = selectedOrgLevel;
      });
      this.loadDetails(this.orgLevel, this.dateOn)
      .then((d: Details) => {
        this.details = d;
        if (this.details) {
          this.previousGroups = _.cloneDeep(this.details.groups);
        }

        this.requestAgencyStaffs();
        if(this.agencyListData.length>0) {
          this.onLoad = false;
        }
      })
      .catch((error: any) => console.log(error))
      .then(() => (this.isLoading = false,
        this.isLoading = false));
        this.onLoad = true;
    }
    const constUserDetails = this.sessionService.getUser();
    this.userName = constUserDetails.name;
    this.userId = constUserDetails.id;
    this.alias = this.sessionService.getAlias();
    this.shiftService.updateShiftRequests().subscribe((addShift) => {
      if(addShift) {
       this.updateShiftRecords();
     }
     else {
      this.isEditMode = false;
     }
    });
  }

  public requestAgencyStaffs(): void {
    let requestdata: any = [];
    let OpenShiftData: any = [];
    this.previousGroups.forEach((el) => {
      requestdata.push(el.rows);
    });
    if (requestdata.length > 0) {
      requestdata.forEach((e) => {
        e.forEach((child) => {
          OpenShiftData.push(child);
        });
      });
    }
    this.shiftService.setGridData(OpenShiftData);

  }

  public getSelectedShift(data): void {
   if(this.agencyListData.length>0) {
      if (data.length >= 1) {
        this.selectedShiftDetails = data;
        
        this.isShiftSelected = true;
        this.setAgencyDetails();
      }
      else {
        this.selectedShiftDetails = [];
        this.isShiftSelected = false;
      }
   }
    else {
        this.selectedShiftDetails = [];
        this.isShiftSelected = false;
      }
  }
  public dataStateChange(state: DataStateChangeEvent): void {    
   this.states = state;
    this.refreshGrid(this.states);
  }
  public setAgencyDetails(): void {
    let DetailsPartners: DetailsPartner[] = this.agencyListData;
    let agencyListObj = this.shiftService.setPartnerModelData(DetailsPartners);
    let tempAgency= Object.assign(agencyListObj);
   this.prepareAgencyList(tempAgency) // remove if agency name  undefined 
    if (this.agency.length < 3) {
      this.scrollCountentSize = this.agency.length;
    }
    else {
      this.scrollCountentSize =3;
    }


  }
  public prepareAgencyList(tempAgency) :void {
    this.agency = [];
    tempAgency.forEach(element => {
      if(element.name != undefined) {
        this.agency.push(element)
      }
    });
  }
  public updateShiftRecords(): void {
    if(this.isMultiSelectedShift) {
      this.updatedShiftDetails = this.updatedShiftDetails.concat(this.multiSelectShift);
      this.isMultiSelectedShift =  false;
      
    }
    if(this.isEditMode) {
      this.updatedShiftDetails.push(this.editShiftDetails);
      this.isEditMode =  false;
      
    }
    this.multiSelectShift = [];
    this.refreshGrid(this.gridState.state)
  }
  public requestedAgencyDetails(details: DetailsPartner[]): void {
    if (this.isEditMode) {
     
      this.editShiftDetails['selectedAgency'] =_.cloneDeep(details);      
      this.updatedShiftDetails.push(this.editShiftDetails);
      this.isEditMode = false;
      this.refreshGrid(this.gridState.state);
    }
    else if (this.isMultiSelectedShift) {

      this.multiSelectShift.forEach(element => {
        element['selectedAgency'] = details;
        this.updatedShiftDetails.push(element);
      });
      this.isMultiSelectedShift = false;
      this.multiSelectShift = [];
      this.refreshGrid(this.gridState.state);
    }
    else {
      this.isRequestAgencyStaff = true;
      let tempShiftDetail =  _.cloneDeep(this.selectedShiftDetails)
      tempShiftDetail.forEach(element => {
        let tempSelectedAgency =  details;
        element['selectedAgency'] = _.cloneDeep(tempSelectedAgency);
        let tempDetails =  element
        this.updatedShiftDetails.push(tempDetails);
        this.setAgencyDetails();
        this.refreshGrid(this.gridState.state);
      });
    }
    this.selectedShiftDetails = [];
  
    this.shiftService.updateShifts(true);
    this.isEditMode = false;
  }
 
  public cancelHandler(dataItem): void {
    this.onLoad = true;
    this.shiftService.deletedDisplayId = dataItem.displayId;
    this.shiftService.isrequestAgencyStaffCancelled = true;
    this.updatedShiftDetails = this.updatedShiftDetails.filter(e => e.displayId != dataItem.displayId);

    this.shiftService.updateShifts(false);
    this.refreshGrid(this.gridState.state);
    this.onLoad = false;
  }
  public editHandler(dataItem): void {
    this.onLoad = true;
    this.isEditMode = true;
    let tempDataItem = _.cloneDeep(dataItem);
    this.editShiftDetails = Object.assign(tempDataItem);
    this.updatedShiftDetails = this.updatedShiftDetails.filter(e => e.displayId != tempDataItem.displayId)
    this.shiftService.editAgencyStaffRequest(tempDataItem);
    this.onLoad = false;
    this.setAgencyDetails();
  }
  private refreshGrid(state:State): void {
    this.updatedShiftDetails.forEach((item) => {
      item.Hours = parseFloat(item.Hours)
    });
    this.updatedShiftDetails.sort((a, b) => a.displayId < b.displayId ? -1 : 1);
    state.take = this.updatedShiftDetails.length;
    this.gridState.view = process(this.updatedShiftDetails, state);
    if(this.updatedShiftDetails.length ==0) {
      this.isRequestAgencyStaff = false;
    }
  }
  public getAgencyCount(dataItem): string {

    return this.selectedAgencyCount = dataItem['selectedAgency'].length >= 2 ? ` ${dataItem['selectedAgency'].length} agencies` : `${dataItem['selectedAgency'].length} agency`;

  }

  public selectedAgencyDetails(dataItem): DetailsAgencyModel[] {
    return dataItem['selectedAgency'];
  }
  public getShiftAttributes(dataItem, attr): string {
    let attribute: string;
    dataItem.forEach(element => {
      if (element.name == attr) {
        attribute = element.attrValues[0].name;

      }
      else {
        '';
      }
    });
    return attribute;
  }
  public async senedRequestAgencyStaff() {
    this.isLoading = true;
    this.storeSelectedDataArr = [];
    let orgdetailsId = this.orgLevelId
    let parent_id = this.orgLevel.parentId;

    this.updatedShiftDetails.forEach((elem, i) => {
      elem.selectedAgency.forEach(element => {   
        element.attributes.filter(el => {
          if(el.name =='instantAccept'){     
            if (el.attrValues[0].name == 'No') {
              el.attrValues[0].name = 'false';
            }
            else if (el.attrValues[0].name == 'Yes')  {
              el.attrValues[0].name = 'true';
            }   
          }
        });           
        });
      let seletedAgency: DetailsAgencyModel = elem.selectedAgency;
      let startDateTime = moment(elem.ShiftStart).toDate();
      let endDateTime = moment(elem.ShiftEnd).toDate();
      let storeSelectedData: DailyOpenShiftDetailsData = {};

      storeSelectedData.Clientid = this.alias;
      storeSelectedData.SlxshiftId = elem.ShiftId;
      storeSelectedData.SlxshiftGroupId = elem.ShiftGroupId;
      storeSelectedData.PositionGroupId = elem.PositionGroupId;
      storeSelectedData.JobCode = elem.JobCode;
      storeSelectedData.JobDesc = elem.JobDescription;
      storeSelectedData.ShiftDate = moment(this.dateOn).format('MM/DD/YYYY');
      storeSelectedData.ShiftStart = moment(startDateTime).format('MM/DD/YYYY HH:mm:ss');
      storeSelectedData.ShiftEnd = moment(endDateTime).format('MM/DD/YYYY HH:mm:ss');
      storeSelectedData.OrgLevelId = this.orgLevel.id;
      storeSelectedData.UnitId = this.updatedShiftDetails[i].UnitId;
      storeSelectedData.DepartmentId = this.updatedShiftDetails[i].DepartmentId;
      storeSelectedData.Hours = this.updatedShiftDetails[i].Hours.toString();
      storeSelectedData.shiftRequestbyId = this.userId;
      storeSelectedData.shiftRequestbyName = this.userName;
      storeSelectedData.SubTypeId = seletedAgency[0].sub_type_id.toString();
      storeSelectedData.PartnerId = "0";
      storeSelectedData.SubscriptionName = seletedAgency[0].sub_type_name;
      storeSelectedData.parentId = parent_id;
      storeSelectedData.AgencyId = 0;
      storeSelectedData.AgencyList = elem.selectedAgency;


      if (!moment(endDateTime).isAfter(startDateTime)) {
        let shitEndTime = this.updatedShiftDetails[i].ShiftEnd;
        let addDaytoWorkDate = moment(storeSelectedData.ShiftDate).add(1, 'days').format('MM/DD/YYYY');
        let nextday = new Date(addDaytoWorkDate + ' ' + shitEndTime);
        storeSelectedData.ShiftEnd = moment(nextday).format('MM/DD/YYYY HH:mm:ss');
      }
      if (storeSelectedData.AgencyList.length > 0) {
        this.storeSelectedDataArr.push(storeSelectedData);
      }
    });

    let orgLevelId = this.orgLevel.id
    await this.management.postRequestStaffing(this.storeSelectedDataArr, orgLevelId).then((status: any) => {
      this.shiftService.isRequestFromDua = true;
      this.shiftService.sendclickEvent();
      this.navigateToDailyUnitGrid();

    }).catch((err: any) => {
      this.navigateToDailyUnitGrid();
    });
    this.navigateToDailyUnitGrid();
    this.isLoading = true;
  }
  public updateMultipleShifts(): void {
    let tempMultiSelectShift =  _.cloneDeep(this.multiSelectShift[0]);
    this.shiftService.editAgencyStaffRequest(tempMultiSelectShift);
    this.multiSelectShift.forEach(element => {
      this.updatedShiftDetails = this.updatedShiftDetails.filter(e => e.displayId != element.displayId);  
    });
    this.setAgencyDetails(); 
  }
  public gridSelectedShift(selection):void {
    if (selection.selectedRows.length !== 0) {
        selection.selectedRows.forEach((row) => {       
        this.multiSelectShift.push(row.dataItem);
      });
    }
    if (selection.deselectedRows.length !== 0) {
      selection.deselectedRows.forEach((row) => {
        this.multiSelectShift = this.multiSelectShift.filter((e) => e.displayId !== row.dataItem.displayId);

      });
    }
    
  }
  public selectedMultiSelectShift() : void {
    if(this.multiSelectShift.length > 1 ) {
      this.isMultiSelectedShift = true;
    }
    else {
      this.isMultiSelectedShift = false;
    }
  }
  public cancelRequestAgencyStaff(): void {
    this.onLoad = true;
    this.updatedShiftDetails = [];
    this.refreshGrid(this.states);
    this.navigateToDailyUnitGrid();
    this.onLoad = false;
  }
  public isDisabledMultiselect(): boolean {
    if(!this.isMultiSelectedShift) {
      return this.updatedShiftDetails.length == 0;
    }
  }
  public navigateToDailyUnitGrid(): void {
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigateByUrl(this.getUrlTree());
  }

  public getUrlTree(): UrlTree {
    let params: any = {
      keepOrgLevelBreadcrmb: true,
      dateOn: moment(this.dateOn).format(appConfig.linkDateFormat)
    };
    return this.router.createUrlTree(
      ['daily_unit_assignment'],
      {
        relativeTo: this.activatedRoute.pathFromRoot[2],
        queryParams: params
      });
  }
}
