import { DialogOptions2, DialogModeSize } from './../../models/dialog-options';
import { Type, Directive, Provider, Input, Renderer2 } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { ModalSettings } from '../../../core/models/index';
import { ModalBehavior, DialogOptions, IDialog, ModalRef } from '../../models/index';
import { Assert } from '../../../framework/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../components/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from '../../components/info-dialog/info-dialog.component';
import { commonConfig } from '../../common.config';
import { screenUtils } from '../../../common/utils/index';
import { appConfig } from '../../../app.config';

@Directive({
  selector: '[modalAnchor]'
})
export class ModalAnchorDirective {
  @Input()
  public set isGlobal(isGlobal: boolean) {
    if (isGlobal) {
      this.setAsGlobal();
    }
  }

  public viewContainer: ViewContainerRef;
  private modalService: ModalService;
  private m_renderer: Renderer2;

  constructor(viewContainer: ViewContainerRef, modalService: ModalService, renderer: Renderer2) {
    Assert.isNotNull(viewContainer, 'viewContainer');
    Assert.isNotNull(modalService, 'modalService');
    this.viewContainer = viewContainer;
    this.modalService = modalService;
    this.m_renderer = renderer;
  }
  public setAsGlobal(): void {
    this.modalService.setGlobalAnchor(this);
  }

  public setElementStyle(elt: any, style: string, value: any): void {
    this.m_renderer.setStyle(elt, style, value);
  }

  public open<TModal>(type: Type<TModal>, title?: string, providers?: Provider[], uniqueId?: string): ModalRef<TModal> {
    return this.modalService.open(type, this.viewContainer, title, providers, uniqueId);
  }

  public openConfirmDialog(title: string, message: string, callback: (result: boolean, uniqueId?: string) => void, confirmOptions?: ConfirmOptions): ConfirmDialogComponent {
    const dialogOptions = new DialogOptions();
    dialogOptions.message = message;
    dialogOptions.height = commonConfig.dialogs.confirmDialog.height;
    dialogOptions.width = commonConfig.dialogs.confirmDialog.width;

    if (!confirmOptions) {
      confirmOptions = new ConfirmOptions();
      confirmOptions.showCancel = true;
      confirmOptions.showOK = true;
    }

    const providers: Provider[] = [
      { provide: DialogOptions, useValue: dialogOptions },
      { provide: ConfirmOptions, useValue: confirmOptions }
    ];

    return this.openDialog(ConfirmDialogComponent, title, dialogOptions, providers, callback);
  }

  public openInfoDialog(title: string, message: string, callback?: (result: boolean, uniqueId?: string) => void): InfoDialogComponent {
    let options: DialogOptions = new DialogOptions();
    options.message = message;
    options.height = commonConfig.dialogs.confirmDialog.height;
    options.width = commonConfig.dialogs.confirmDialog.width;
    return this.openDialog(InfoDialogComponent, title, options, undefined, callback);
  }

  public openInfoDialogEx(title: string, options: DialogOptions, callback?: (result: boolean, uniqueId?: string) => void): InfoDialogComponent {
    return this.openDialog(InfoDialogComponent, title, options, undefined, callback);
  }

  public openDialog<TModal extends IDialog>(type: Type<TModal>, title: string, options: DialogOptions, providers?: Provider[], callback?: (result: boolean, uniqueId?: string) => void): TModal {
    let modalSettings: ModalSettings = this.modalService.createDefaultModalSettings();
    modalSettings.maximized = false;

    // add header height if we have height
    if (!options.hideTitleBar) {
      if (options.height !== undefined) {
        options.height += appConfig.popupHeaderHeight;
      }
    }

    if (screenUtils.isMobile && options.fullHeightOnMobile) {
      options.height = screen.height;
    } else {
      modalSettings.height = options.height;
    }

    if ((options.width !== undefined && screen.width < options.width) || (options.height !== undefined && screen.height < options.height)) {
      modalSettings.maximized = true;
    }

    modalSettings.maxHeight = screen.height;

    modalSettings.width = options.width;
    modalSettings.resizable = false;
    modalSettings.title = !options.hideTitleBar ? title : false;
    modalSettings.actions = [ModalBehavior.close];
    modalSettings.showCloseButton = options.showCloseButton;
    modalSettings.useWindowWrapper = !options.hideTitleBar;
    modalSettings.className = options.className;
    modalSettings.headerClassName = options.headerClassName;
    let rp: Provider[];
    if (providers) {
      rp = providers;
    } else {
      rp = [{ provide: DialogOptions, useValue: options }];
    }

    let dialog: TModal;

    let ref: ModalRef<TModal>;
    ref = this.modalService.openEx<TModal>(type, this.viewContainer, modalSettings, rp);
    options.windowUniqueId = ref.uniqueId;
    dialog = ref.reference;

    if (modalSettings.modal) {
      this.m_renderer.addClass(this.viewContainer.element.nativeElement, 'modal-overlay');
    }

    this.modalService.subscribeOnClose((result: { uniqId: string }) => {

      let uniqueId: string = result.uniqId;

      let count: number = this.modalService.getOpenedDialogsCount();

      if (count === 0 || this.modalService.globalAnchor !== this) {
        this.m_renderer.removeClass(this.viewContainer.element.nativeElement, 'modal-overlay');
      }

      if (callback && options.windowUniqueId === uniqueId) {
        callback(dialog.dialogResult, options.windowUniqueId);
      }
    });

    return dialog;
  }

  public openEx<TModal>(type: Type<TModal>, settings: ModalSettings, providers?: Provider[]): ModalRef<TModal> {
    return this.modalService.openEx(type, this.viewContainer, settings, providers);
  }

  public closeWindow(uniqId: string): void {
    this.modalService.closeWindow(uniqId);
  }

  public subscribeOnOpen(callback: (e: any) => void): void {
    Assert.isNotNull(callback, 'callback');
    this.modalService.subscribeOnOpen(callback);
  }

  public subscribeOnClose(callback: (e: any) => void): void {
    Assert.isNotNull(callback, 'callback');
    this.modalService.subscribeOnClose(callback);
  }

  public openDialog2<TModal extends IDialog>(type: Type<TModal>, title: string, options: DialogOptions2, providers?: Provider[], callback?: (result: boolean, uniqueId?: string) => void): TModal {
    let modalSettings: ModalSettings = this.modalService.createDefaultModalSettings();
    modalSettings.maximized = false;

    if (screenUtils.isMobile && options.fullHeightOnMobile) {
      options.height = screen.height;
      options.minWidth = null;
    } else {
      switch(options.modeSize) {
        case DialogModeSize.grid:
          modalSettings.height = '70%';
          modalSettings.width = '85%';
          modalSettings.minHeight = options.minHegiht ? options.minHegiht : 400;
        break;
        case DialogModeSize.dialog:
          modalSettings.height = '30%';
          modalSettings.width = '30%';
          modalSettings.minHeight =  options.minHegiht ? options.minHegiht : 220;
        break;
        case DialogModeSize.vertical:
          modalSettings.height = '70%';
          modalSettings.width = '45%';
          modalSettings.minHeight = 400;
        break;
        default:
          modalSettings.height = options.height;
          modalSettings.width = options.width;
          modalSettings.minHeight = options.minHegiht ? options.minHegiht : undefined;
      }
      modalSettings.minWidth = options.minWidth ? options.minWidth  : 450;
    }
    modalSettings.topmost = options.topmost;
    modalSettings.zIndexForTopMost = options.zIndexForTopMost;

    if ((options.width !== undefined && screen.width < options.width) || (options.height !== undefined && screen.height < options.height)) {
      modalSettings.maximized = true;
    }

    modalSettings.maxHeight = screen.height;
    modalSettings.maxWidth = 1700;
    modalSettings.resizable = false;
    modalSettings.title = title;
    modalSettings.actions = [ModalBehavior.close];
    modalSettings.showCloseButton = true;
    modalSettings.className = options.className ? options.className :'slx-blue-theme-2';
    modalSettings.ifHeaderIcon = options.ifHeaderIcon ? options.ifHeaderIcon : false;
    modalSettings.headerIconTooltip = options.headerIconTooltip ? options.headerIconTooltip :'';
    let rp: Provider[];
    if (providers) {
      rp = providers;
    } else {
      rp = [{ provide: DialogOptions, useValue: options }];
    }

    let dialog: TModal;

    let ref: ModalRef<TModal>;
    ref = this.modalService.openEx<TModal>(type, this.viewContainer, modalSettings, rp);
    options.windowUniqueId = ref.uniqueId;
    dialog = ref.reference;

    if (modalSettings.topmost) {
      this.m_renderer.addClass(this.viewContainer.element.nativeElement, 'topmost-modal-overlay');
    } else if (modalSettings.modal) {
      this.m_renderer.addClass(this.viewContainer.element.nativeElement, 'modal-overlay');
    }

    this.modalService.subscribeOnClose((result: { uniqId: string }) => {

      let uniqueId: string = result.uniqId;

      let count: number = this.modalService.getOpenedDialogsCount();

      if (options.windowUniqueId === uniqueId) {

        if (modalSettings.topmost) {
          this.m_renderer.removeClass(this.viewContainer.element.nativeElement, 'topmost-modal-overlay');
        } else {
          this.m_renderer.removeClass(this.viewContainer.element.nativeElement, 'modal-overlay');
        }

        if (callback) {
          callback(dialog.dialogResult, options.windowUniqueId);
        }
      }
    });
    return dialog;
  }
}
