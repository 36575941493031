<!--<router-outlet name="leftSidebar"></router-outlet>-->
<main class="main-box" [ngClass]="{ 'right-sidebar-open': (isRightSidebarOpen | async), 'left-sidebar-open': (isLeftSidebarOpen | async) }">
<div *ngIf="!availability?.isReady" class="spinner-container flex-container shown">
    <div class="blur-container"></div>
    <div class="spinner"></div>
  </div>

  <div class="slx-content" *ngIf="availability?.isReady && availability?.isAvailable">
    <router-outlet></router-outlet>
  </div>

  <div class="slx-content" *ngIf="availability?.isReady && !availability?.isAvailable">
    <slx-unavailability-notification
      [availableLevels]="availability?.supportedOrgTypes"
    ></slx-unavailability-notification>
  </div>
</main>

