import { Component, OnInit, OnDestroy } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { SidebarActions } from '../../actions/index';

@Component({
  moduleId: module.id,
  selector: 'slx-header-left-sidebar-button',
  templateUrl: 'header-left-sidebar-button.component.html',
  styleUrls: ['header-left-sidebar-button.component.scss'],
})
export class HeaderLeftSidebarButtonComponent implements OnInit, OnDestroy {
  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  public state: {
    isLeftSidebarOpen: boolean;
  };

  @unsubscribe()
  private isLeftSidebarOpenSubscripion: Subscription;
  private sidebarActions: SidebarActions;

  constructor(sidebarActions: SidebarActions) {
    this.sidebarActions = sidebarActions;
    this.state = {
      isLeftSidebarOpen: false
    };
  }

  public ngOnInit(): void {
    this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe((value: boolean) => {
      this.state.isLeftSidebarOpen = value;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }


  public toggleLeftSidebar(): void {
    this.sidebarActions.setLeftSidebar(!this.state.isLeftSidebarOpen);
  }
}
