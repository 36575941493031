import { Injectable } from "@angular/core";
'use strict';

export class DialogOptions {
  public message?: string;
  public windowUniqueId: string;
  public width?: number;
  public height?: number;
  public fullHeightOnMobile: boolean;
  public hideTitleBar?: boolean;
  public displayBlock: boolean;
  public showCloseButton: boolean;
  public className: string;
  public headerClassName: string;
  public note : string;
  public agencyName : string;
  public isMobile?: boolean;
}

export interface  IDialog {
  dialogResult: boolean;
}

@Injectable()
export class DialogOptions2 {
  public message?: string;
  public windowUniqueId: string;
  public width?: number | string;
  public height?: number | string;
  public minHeight?: number;
  public fullHeightOnMobile: boolean;
  public displayBlock: boolean;
  public modeSize: DialogModeSize = DialogModeSize.custom;
  public minHegiht?: number;
  public minWidth?: number;
  public topmost : boolean;
  public zIndexForTopMost : number;
  public className?: string;
  public ifHeaderIcon?: boolean;
  public headerIconTooltip?: string;
}

export enum DialogModeSize {
  grid,
  dialog,
  custom,
  vertical
}

