import { Component, Provider, ViewChild, DoCheck } from '@angular/core';
import * as _ from 'lodash';
import { UntypedFormGroup } from '@angular/forms';
import { DialogModeSize, DialogOptions, DialogOptions2, IDialog } from '../../../common/models/index';
import { ModalService } from '../../../common/services/modal/modal.service';

import {
  ReportDefinition, ReportParameter
} from '../../models/index';
import { Assert } from '../../../framework/index';
import { ReportActionType } from '../../models/report-action-type';
import * as moment from 'moment';
import { ReportsManagementService } from '../../services';
import { RnHrsMinWarningDIalogComponent } from '../report-parameters/rn-hrs-min-warning-dialog/rn-hrs-min-warning-dialog.component';

@Component({
  moduleId: module.id,
  templateUrl: 'generate-report-dialog.component.html',
  styleUrls: ['generate-report-dialog.component.scss']
})
export class GenerateReportDialogComponent implements IDialog,DoCheck  {
  isRNHrsSelected: boolean= false;
  public get parameters(): ReportParameter[] {
    return this.reportDefinition.parameters;
  }

  @ViewChild('dialogForm', { static: true })
  public dialogForm: UntypedFormGroup;

  public dialogResult: boolean;
  private readonly start_date: string = 'start_date';
  private readonly end_date: string = 'end_date';
  private readonly retro_pay: string = 'retro_pay';
  private readonly wage_comp1: string = 'EEO_Report_Wage_comp1';
  private readonly all_missing_punches: string = 'ta_all_historical_missing_punches';
  private readonly total_hrs: string = 'Total_hrs';
  private readonly consec_hrs :string = 'consecutive_hrs';
  public reportAction: ReportActionType = ReportActionType.Preview;
  public isValidResult: boolean = false;

  public get reportDefinition(): ReportDefinition {
    return this.m_reportDefinition;
  }

  public set reportDefinition(value: ReportDefinition) {
    this.m_reportDefinition = value;
  }

  public allParametersFilled(): boolean {
    let eligibleParameters = _.filter(this.reportDefinition.parameters, (parameter: ReportParameter) => parameter.isVisible);
    this.isValidResult = _.every(eligibleParameters, (parameter: ReportParameter): boolean => {
      let max = (parameter.name == this.consec_hrs) ? 24 : 299.99;
      if (parameter.dataType.isLookup) {
        return true;
      }
      if (parameter.name === 'month_id' && (parameter.value < 1 || parameter.value > 12)) {
        return false;
      }
      if (parameter.dataType.name === 'int' && _.size(!(_.isUndefined(parameter.value) || _.isNull(parameter.value)) ? parameter.value.toString() : '') === 0) {
        return false;
      }
      if (parameter.name === this.start_date || parameter.name === this.end_date) {
        let retroPay: ReportParameter = _.find(this.reportDefinition.parameters, (p: ReportParameter) => p.name === this.retro_pay);
        let wageComp1: boolean = parameter.reportDefinition && _.isEqual(parameter.reportDefinition.name, this.wage_comp1);
        let allMissingPunches: boolean = parameter.reportDefinition && _.isEqual(parameter.reportDefinition.name, this.all_missing_punches);
        if (retroPay || wageComp1) {
          let end: ReportParameter = _.find(this.reportDefinition.parameters, (p: ReportParameter) => p.name === this.end_date);
          let start: ReportParameter = _.find(this.reportDefinition.parameters, (p: ReportParameter) => p.name === this.start_date);
          if (moment(end.value).diff(start.value, 'day') > 366) {
            return false;
          }
        }
        if (allMissingPunches) {
          let end: ReportParameter = _.find(this.reportDefinition.parameters, (p: ReportParameter) => p.name === this.end_date);
          let start: ReportParameter = _.find(this.reportDefinition.parameters, (p: ReportParameter) => p.name === this.start_date);
          if (moment(end.value).diff(start.value, 'day') > 89) {
            return false;
          }
        }
      }
      if((parameter.name == this.total_hrs || parameter.name == this.consec_hrs) && parameter.defaultValue==true && (parameter.value <= 0 || parameter.value > max)){
        return false;
      }
      let end: ReportParameter = _.find(this.reportDefinition.parameters, (p: ReportParameter) => p.name === this.end_date);
      let start: ReportParameter = _.find(this.reportDefinition.parameters, (p: ReportParameter) => p.name === this.start_date);
      if (start && end && moment(end.value).diff(start.value, 'day') < 0) {
        return false;
      }
      return !(_.isUndefined(parameter.value) || _.isNull(parameter.value));
    });
    return this.isValidResult;
  }

  private m_reportDefinition: ReportDefinition;
  private m_exportType: string;

  public static openDialog(request: ReportDefinition, modalService: ModalService, callback: (result: boolean) => void): GenerateReportDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.width = 480;
    dialogOptions.height = 557;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.displayBlock = true;
    dialogOptions.modeSize = DialogModeSize.custom;
    dialogOptions.ifHeaderIcon = (request.name == 'PBJ_Min_RN_Hrs' || request.name == 'PBJRNHoursbyDate');
    dialogOptions.headerIconTooltip = (request.name == 'PBJ_Min_RN_Hrs' ? 'This report will reflect recorded timecard hours for past dates and scheduled hours for future dates' : '') || (request.name == 'PBJRNHoursbyDate' ?'This report only reflects timecard hours for past approved pay periods': '');
          let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ReportDefinition,
        useValue: request
      }
    ];
    let dialog: GenerateReportDialogComponent = modalService.globalAnchor
      .openDialog2(GenerateReportDialogComponent, 'Generate Report', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result);
      });
    return dialog;
  }

  constructor(reportDefinition: ReportDefinition, private options: DialogOptions, private modalService: ModalService, private reportManService: ReportsManagementService) {
    Assert.isNotNull(reportDefinition, 'reportDefinition');
    this.reportDefinition = reportDefinition;
  }

  ngDoCheck() {
    this.allParametersFilled();
  }

  public onOk(): void {
    this.dialogResult = true;
    if(this.reportDefinition.parameters.find(x => x.name == "Show_dates")){
      let isShowDates = this.reportDefinition.parameters.find(x => x.name == "Show_dates");
      let zeroHr = this.reportDefinition.parameters.find(x => x.name == "Zero_hrs");
      let consecHr = this.reportDefinition.parameters.find(x => x.name == "consecutive_hrs");
      let totalHr = this.reportDefinition.parameters.find(x => x.name == "Total_hrs");
      if ( (isShowDates.defaultValue == true && zeroHr.defaultValue!=true && totalHr.defaultValue!=true && consecHr.defaultValue!=true) || (zeroHr.defaultValue!=true && totalHr.defaultValue!=true && consecHr.defaultValue!=true)) {
        RnHrsMinWarningDIalogComponent.openDialog( this.modalService, (result: boolean) => {
        });
      }
      else this.modalService.closeWindow(this.options.windowUniqueId);
    }
    else this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
