<div class="dashboard-block">
  <div class="dashboard-container" [ngClass]="{ 'menu-open': isLeftSidebarOpen | async }">
  <slx-client-message class="messages"></slx-client-message>
    <div class="dashboard">
      <div class="dashboard-holder">
        <header class="dashboard__header">
          <div class="dashboard__welcome">
            <h1 class="dashboard__phrase">Hello,
              <span class="dashboard__user">{{ user?.name }}</span>
            </h1>
            <div class="dashboard__weather">
              <slx-weather-widget [orgLevelLocation] ="orgLevelLocation"></slx-weather-widget>
            </div>
          </div>
          <div class="dashboard__info">
            <p *ngIf="appConfig.showMessagesNotifications && hasUnreadMessages()">You have
              <strong>{{ user.messagesUnread }} messages</strong> and you have some items on your dashboard that need your attention.</p>
            <p *ngIf="hasItemsToReview">You have some items on your dashboard.</p>
          </div>
        </header>
        <section class="dashboard__body">
          <div class="dashboard__apps">
            <div class="dashboard-subtitle">Dashboards
              <span (click)="openAppConfig()" class="dashboard-edit-button-app"><i class="icon fas fa-cog" aria-hidden="true"></i>Edit</span>
            </div>
            <hr class="d-line">
            <div *ngIf="appsIsEmpty"
                (click)="openAppConfig()"
                class="empty-app app collapsed">
              <a class="app__logo fav-logo"><i class="fa fa-plus" aria-hidden="true"></i></a>
              <div class="app__content">
                <a class="app__url" >Add Applications</a>
              </div>
            </div>
            <ng-container *ngFor="let app of userAppsPref" [ngSwitch]="app.name">
              <ng-container *ngIf="!isHidden(app)">
                <slx-so-dashboard *ngSwitchCase="'Scheduler'" [application]="app" (onLoading)="onLoading($event)" [progressbar]="isLoading" class="dashboard__app"></slx-so-dashboard>
                <slx-ta-dashboard *ngSwitchCase="'Time'" [application]="app" (onLoading)="onLoading($event)" [progressbar]="isLoading" class="dashboard__app" [orgLevel]="orgLevel"></slx-ta-dashboard>
                <ng-container *ngSwitchCase="'HR'">
                  <ng-container *ngFor="let dashboard of app.dashboards" [ngSwitch]="dashboard.link">
                    <slx-leave-management-dashboard *ngSwitchCase="'leave_management'" class="dashboard__app" [orgLevel]="orgLevel" [dashboard]="dashboard"></slx-leave-management-dashboard>
                  </ng-container>
                </ng-container>
                <slx-application-item  *ngSwitchCase="'Reports'" [application]="app"></slx-application-item>
                <slx-application-item  *ngSwitchCase="'PBJ'" [application]="app"></slx-application-item>
                <slx-application-item  *ngSwitchCase="'more'" [application]="app" [isExternalLink]='true'></slx-application-item>
              </ng-container>
            </ng-container>
          </div>
          <div class="favorites-dashboard">
            <div class="dashboard-subtitle">Favorites
              <span (click)="openFavoritesConfig()" class="dashboard-edit-button"><i class="icon fas fa-cog" aria-hidden="true"></i>Edit</span>
            </div>
            <hr class="d-line">
            <div class="favorite-items">
                <div *ngIf="favoriteIsEmpty"
                    (click)="openFavoritesConfig()"
                    class="empty-app app collapsed">
                  <a class="app__logo fav-logo"><i class="fa fa-plus" aria-hidden="true"></i></a>
                  <div class="app__content">
                    <a class="app__url" >Add Favorites</a>
                  </div>
                </div>

                <slx-favorite-item *ngFor="let item of favoriteList"
                    [menuItem]="item"></slx-favorite-item>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
