import { DetailGroup } from './detail-group';
import { DetailColumn } from './detail-column';
import * as _ from 'lodash';

export class Details {
  public totalHours: number;
  public dateOn: Date;
  public censusCount: number;
  public groups: DetailGroup[];
  public columns: DetailColumn[];
  public canReplaceEmployee: boolean;
  public canAgencyStaffingRequest: boolean;
  public canPartnerModule: boolean;
  public canAgencyShiftCancelRequest: boolean;
  public canEditScheduledApprovePayPeriod : boolean;
  public canEditOpenShiftCountAction : boolean;
  public get totalIdealHours(): number {
    let totalIdealHours = 0;
    _.each(this.groups, (group) => {
      totalIdealHours += group.groupedIdealScheduleHours;
    });
    return totalIdealHours;
  }
  public get scheduledHours(): number {
    let scheduledHours = 0;
    _.each(this.groups, (group) => {
      scheduledHours += group.groupedScheduledHours;
    });
    return scheduledHours;
  }
  public get idealSlots(): number {
    let idealSlot = 0;
    _.each(this.groups, (group) => {
      idealSlot += group.parLevel;
    });
    return idealSlot;
  }
  public get scheduledSlots(): number {
    let scheduledSlot = 0;
    _.each(this.groups, (group) => {
      scheduledSlot += group.groupedScheduledCount;
    });
    return scheduledSlot;
  }
}