<div class="window-container {{className}}" [attr.modalId]="modalId">
  <div class="window-header {{headerClassName}}">
    <span class="title-label" *ngIf="title" [title]="!ifHeaderIcon ? title :''" >{{title}}
      <i aria-hidden="true" *ngIf="ifHeaderIcon" class="fa fa-info-circle info-icon header-tooltip-icon"><span [slxTooltip]="headerIconTooltip" [tipClass]="'wide-tip'" tipPosition="right"></span></i>
    </span>
    <div class="action-buttons">
      <button *ngIf="showCloseButton" type="button" class="close-button" (click)="closeWindow()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="window-content">
    <ng-content></ng-content>
  </div>
</div>