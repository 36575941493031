import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ReportDefinition, ReportParameter } from '../../../models/index';
import { Observable ,  Subscription } from 'rxjs';
import { ReportsApiService } from '../../../../reports/services/reports-api.service';

@Component({
  moduleId: module.id,
  selector: 'slx-report-parameters-list',
  templateUrl: 'parameters-list.component.html',
  styleUrls: ['parameters-list.component.scss']
})
export class ParametersListComponent implements OnInit {
  @Input()
  public parameters: ReportParameter[];
  @Input()
  public reportDefinition : ReportDefinition;
  selectedParams: ReportParameter[];
   rnHrsparameter: ReportParameter;
  public rnReports: boolean = false;

  constructor(private reportAPIService :ReportsApiService) {
  }
  ngOnInit() {
    if(this.reportDefinition.group == 'PBJ'){
    if(this.parameters.find(x=> x.name =='Show_dates')){
    if(this.parameters.find(x => x.name == 'end_date').value!=undefined) this.parameters.find(x => x.name == 'end_date').value = moment().startOf('day').toDate();
  }
    if(this.reportDefinition.name === 'PBJRNHoursbyDate' || this.reportDefinition.name === 'PBJ_Min_RN_Hrs') this.rnReports = true;
  }
    this.selectedParams = this.parameters;
    this.rnHrsparameter = this.parameters.find(x => x.name == 'Show_dates');
    if (this.rnHrsparameter != null && this.rnHrsparameter != undefined) {
    if(this.rnHrsparameter.value==true){
       this.rnHrsparameter.value = false;
    }
      this.parameters.find(x => x.name == "Zero_hrs").value = 0;
      if(this.rnHrsparameter.value!= true){
        this.parameters.find(x => x.name == 'consecutive_hrs').value = 0.00;
        this.parameters.find(x => x.name == 'Total_hrs').value = 0.00;
      }
    }
  }
}
