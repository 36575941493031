import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, Observable, Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/operators';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { ManagementBaseService } from '../../../core/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { IUser } from '../../../authentication/store/index';
import { OrgLevel, User } from '../../../state-model/models/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { PayCodeDetailsApiService } from './pay-code-details-api.service';
import { PayCodeDetailsContainer } from '../../models/pay-code-details/pay-code-details-container';
import * as _ from 'lodash';
import { RuleFormulaMapService } from './rule-formula-map.service';
import { PaycodeExceptionDto } from '../../models/pay-code-details/dtos/paycode-exception.dto';

@Injectable()
export class PayCodeDetailsManagementService extends ManagementBaseService<any, any> implements IConfigurationManagementService {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['session', 'user'])
  public user$: Observable<IUser>;
  editItemCmd$: ReplaySubject<any>;

  public get container(): PayCodeDetailsContainer {
    return this.m_container;
  }

  private m_container: PayCodeDetailsContainer = new PayCodeDetailsContainer();

  editingItem: any;
  isEditingNewItem: boolean;
  addItemCmd$: ReplaySubject<any>;
  viewRefresh$: Subject<boolean>;
  removeItemsCmd$: ReplaySubject<any>;

  @unsubscribeInService()
  private appDataSubscription: Subscription;

  private currentOrgLevel: OrgLevel;
  private currentUser: User;

  constructor(
    public access: AccessManagementService,
    public changeService: ChangeManagementService,
    private api: PayCodeDetailsApiService,
    private ruleFormulaMap: RuleFormulaMapService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute
  ) {
    super();
    this.addItemCmd$ = new ReplaySubject<any>();
    this.editItemCmd$ = new ReplaySubject<any>();
    this.viewRefresh$ = new Subject<boolean>();
    this.removeItemsCmd$ = new ReplaySubject<any>();
  }

  onSaveItem: (result: { dataItem: any; isNew: boolean; }) => void;
  onRemoveItem: (item: any) => void;
  onEditItem: (item: any) => void;
  onCancelEditItem: () => void;
  setSelectedCount: (count: number) => void;
  onAddItem: (item: any) => void;
  markAsDirty(): void {
    throw new Error('Method not implemented.');
  }

  public init(): void {
    this.onStateChanged$.next({ isLoading: true });

    this.access.allowCorporationLevel = false;
    this.access.allowOrganizationLevel = true;
    this.access.allowDepartmentLevel = true;

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id) {
        this.m_container.id = +id;
      }
    });

    this.appDataSubscription = this.orgLevel$.pipe(
      combineLatest(this.user$)
    ).subscribe((value: [OrgLevel, User]) => {
      let [orgLevel, user]: [OrgLevel, User] = value;
      if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
        return;
      }
      this.currentOrgLevel = orgLevel;
      this.currentUser = user;
      this.access.orgLevelType = this.currentOrgLevel.type;
      if (this.m_container.id) {
        this.fetchPaycodeExceptions(this.m_container.id);
      } else {
        this.onLoaded$.next(this.m_container);
      }
      this.onStateChanged$.next({ isLoading: false });
    });
  }

  // AdditionalRequirements
  public fetchAdditionalRequirements(id: number): Promise<void> {
    return this.api.getAdditionalRequirements(id).then(response => {
      this.m_container.additionalRequirements = response.additionalRequirements;
      this.m_container.updateRecords();
      this.onLoaded$.next(this.m_container);
    }).catch(error => {
      if (error.status === 404 || (error.error && error.error.status === 404)) {
        console.warn('No additional requirements found for exception ID:', id);
        this.m_container.additionalRequirements = [];
        this.m_container.updateRecords();
        this.onLoaded$.next(this.m_container);
      } else {
        console.error('Error fetching additional requirements by exception ID:', error);
      }
    });
  }

  public saveAdditionalRequirement(exceptionId: number, data: any): Promise<void> {
    return this.api.saveAdditionalRequirements(exceptionId, data).then(() => {
      this.notificationsService.success('Success', 'Additional requirement saved successfully');
      if (this.m_container.id) {
        return this.fetchAdditionalRequirements(exceptionId);
      }
    }).catch(error => {
      console.error('Error saving additional requirement:', error);
      throw error;
    });
  }

  public deleteAdditionalRequirement(id: number): void {
    this.api.deleteAdditionalRequirement(id).then(() => {
      this.notificationsService.success('Success', 'Additional requirement deleted successfully');
      if (this.m_container.id) {
        this.fetchAdditionalRequirements(this.m_container.id);
      }
    }).catch(error => {
      console.error('Error deleting additional requirement:', error);
    });
  }

  // PaycodeExceptions
  public fetchPaycodeExceptions(id: number): Promise<void> {
    return this.api.getPaycodeExceptions(id, this.currentOrgLevel.organizationId).then(response => {
      // Trim whitespace from exWorkStatus
      response.exceptions = response.exceptions.map(exception => {
        if (exception.exWorkStatus) {
          exception.exWorkStatus = exception.exWorkStatus.trim();
        }
        return exception;
      });

      this.m_container.exceptions = response.exceptions;
      this.m_container.updateRecords();
    }).catch(error => {
      console.error('Error fetching paycode exceptions:', error);
    });
  }

  public async savePaycodeException(data: any): Promise<PaycodeExceptionDto> {
    try {
      const savedException = await this.api.savePaycodeException(data);
      this.notificationsService.success('Success', 'Paycode exception saved successfully');

      if (this.m_container.id) {
        await this.fetchPaycodeExceptions(this.m_container.id);
      }

      return savedException;
    } catch (error) {
      console.error('Error saving paycode exception:', error);
      throw error;
    }
  }

  public deletePaycodeException(id: number): void {
    this.api.deletePaycodeException(id).then(() => {
      this.notificationsService.success('Success', 'Paycode exception deleted successfully');
      if (this.m_container.id) {
        this.fetchPaycodeExceptions(this.m_container.id);
      }
    }).catch(error => {
      console.error('Error deleting paycode exception:', error);
    });
  }

  // Rules
  public fetchRules(id: number): Promise<void> {
    return this.api.getRules(id).then(async response => {
      this.m_container.rules = response.rules;

      for (let rule of this.m_container.rules) {
        const formulas = await this.api.getRuleFormulasByRuleId(rule.id);
        rule.ruleFormulas = this.ruleFormulaMap.mapToModels(formulas);
      }

      this.m_container.updateRecords();
      this.onLoaded$.next(this.m_container);
    }).catch(error => {
      console.error('Error fetching rules:', error);
    });
  }

  public fetchRulesByExceptionId(exceptionId: number): Promise<void> {
    return this.api.getRulesByExceptionId(exceptionId).then(async response => {
      this.m_container.rules = response.rules;

      for (let rule of this.m_container.rules) {
        const formulas = await this.api.getRuleFormulasByRuleId(rule.id);
        rule.ruleFormulas = this.ruleFormulaMap.mapToModels(formulas);
      }

      this.m_container.updateRecords();
      this.onLoaded$.next(this.m_container);
    }).catch(error => {
      if (error.status === 404 || (error.error && error.error.status === 404)) {
        console.warn('No rules found for exception ID:', exceptionId);
        this.m_container.rules = [];
        this.m_container.updateRecords();
        this.onLoaded$.next(this.m_container);
      } else {
        console.error('Error fetching rules by exception ID:', error);
      }
    });
  }

  public async saveRule(data: any): Promise<void> {
    try {
      // Save the rule itself
      var ruleDto = await this.api.saveRule(data);
      var ruleId = ruleDto.id;
      var isNewRule = ruleId != data.id;

      if (isNewRule) {
        data.ruleFormulas.forEach(f => {
          f.ruleId = ruleId;
          // Ensure new formulas have id=0 so backend knows to assign a new minimal id
          if (!f.id || f.id === 0) {
            f.id = 0;
          }
        });
      }

      // Fetch existing rule formulas from backend
      const existingFormulas = await this.api.getRuleFormulasByRuleId(data.id);

      // Identify formulas to be deleted
      const formulasToDelete = existingFormulas.filter(existingFormula =>
        !data.ruleFormulas.some(formula => formula.id === existingFormula.id)
      );

      // Delete formulas that are no longer present in the data
      for (let formula of formulasToDelete) {
        await this.api.deleteRuleFormula(data.id, formula.id);
      }

      // Save or update existing formulas
      for (let i = 0; i < data.ruleFormulas.length; i++) {
        const formula = data.ruleFormulas[i];
        const savedFormula = await this.api.saveRuleFormula(formula);
        // Update the formula ID with the newly assigned one if it was new
        if (formula.id === 0) {
          data.ruleFormulas[i].id = savedFormula.id;
        }
      }

      this.notificationsService.success('Success', 'Rule and formulas saved successfully');

      // Refresh the container
      if (this.m_container.id) {
        await this.fetchRulesByExceptionId(this.m_container.id);
      }
    } catch (error) {
      console.error('Error saving rule and formulas:', error);
      throw error;
    }
  }

  public async deleteRule(id: number): Promise<void> {
    try {
      const ruleFormulas = await this.api.getRuleFormulasByRuleId(id);
      for (let formula of ruleFormulas) {
        await this.api.deleteRuleFormula(id, formula.id);
      }

      await this.api.deleteRule(id);
      this.notificationsService.success('Success', 'Rule and formulas deleted successfully');
      if (this.m_container.id) {
        await this.fetchRulesByExceptionId(this.m_container.id);
      }
    } catch (error) {
      console.error('Error deleting rule and formulas:', error);
      throw error;
    }
  }

  public validateRule(whereClause: string): Promise<boolean> {
    return this.api.validateRule(whereClause).then(isValid => {
      if (!isValid) {
        this.notificationsService.error('Validation Failed', 'The SQL WHERE clause is invalid');
      }
      return isValid;
    }).catch(error => {
      console.error('Error validating rule:', error);
      return false; // Return false if there's an error
    });
  }

  public async deleteRuleFormula(ruleId: number, formulaId: number): Promise<void> {
    try {
      await this.api.deleteRuleFormula(ruleId, formulaId);
      this.notificationsService.success('Success', 'Rule formula deleted successfully');
    } catch (error) {
      console.error('Error deleting rule formula:', error);
      throw error;
    }
  }
}